import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as timezone from 'dayjs/plugin/timezone';
import { Injectable } from '@angular/core';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    formatDate(
        date: string | number = '',
        dateFormat: string = '',
        validateDate = false,
        isUtc = false
    ): string {
        if (validateDate && !dayjs(date).isValid()) {
            return '';
        }

        let newDate = dayjs(date);
        if (isUtc) {
            newDate = newDate.utc();
        }

        return newDate.format(dateFormat);
    }

    getAddedDays(daysToAdd: number): dayjs.Dayjs | string {
        return dayjs().add(daysToAdd, 'd').utc();
    }

    getAddedHours(hoursToAdd: number): string {
        return dayjs().add(hoursToAdd, 'h').utc().format();
    }

    getUnix(date: string): number {
        return dayjs(date).unix();
    }

    getMonthByName(date: string): string {
        return dayjs(date).format('MMM');
    }

    getIsDateAfter(startDate: string, endDate: string): boolean {
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        return start.isAfter(end);
    }
}
