import { RealTimeDatabaseModel } from '@app/models/rtdb.model';
import { resetUserToken, setRTDBNode } from '../actions';
import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

const initialState = new RealTimeDatabaseModel();
// TODO getContentFailed to use data model:HG

const rtdbReducer = createReducer(
    initialState,
    on(setRTDBNode, (state, action) => {
        const _r = { ...state };
        _r[action.node] = action.data;

        return _r;
    }),
    on(resetUserToken, (state) => {
        const _r = { ...state };
        _r.menuConfig = null;
        return _r;
    })
);

export default function reducer(state: any, action: any): any {
    return rtdbReducer(state, action);
}
