<div class="modal-header">
  <h4 class="modal-title">{{ dialogMessage.header }}</h4>
</div>
<div class="modal-body">
  <p *ngIf="!cancelRequestButton && !declineButton && !resetPassword">
    {{ dialogMessage.body }}
  </p>
  <label
    *ngIf="cancelRequestButton || declineButton || resetPassword"
    for="reason"
    class="labelStyle"
    >{{
      (resetPassword ? "DIALOG_MESSAGES.EMAIL" : "DIALOG_MESSAGES.REASON")
        | translate
    }}</label
  >
  <textarea
    *ngIf="cancelRequestButton || declineButton"
    [(ngModel)]="reasonText"
    #reason
    type="text"
    class="form-control"
    id="reason"
    placeholder="{{ 'DIALOG_MESSAGES.REASON_GOES_HERE' | translate }}"
    (keyup)="checkReason($event)"
    rows="4"
  ></textarea>

  <input
    *ngIf="resetPassword"
    [(ngModel)]="email"
    #reset
    type="text"
    class="form-control"
    id="reset"
    placeholder="{{ 'DIALOG_MESSAGES.ENTER_EMAIL' | translate }}"
    (keyup)="onCheckEmail($event)"
  />
  <div
    *ngIf="resetPassword && (!email || email == '') && inputTouched"
    class="text text-danger"
  >
    {{ "FORMS.EMAIL" | translate }}
    {{ "FORMS.VALIDATION_MESSAGE" | translate }}
  </div>
  <div
    class="text text-danger"
    style="margin: 0px !important"
    *ngIf="resetPassword && !isValidEmail && email != '' && inputTouched"
  >
    {{ "FORMS.INVALID_EMAIL_ADDRESS" | translate }}
  </div>
</div>

<div class="modal-footer">
  <!--Action Buttons-->
  <div *ngFor="let item of dialogMessage.viewButtons">
    <button
      class="btn mr-1"
      [disabled]="
        item == 'cancelRequest' || item == 'decline'
          ? !isReasonValid
          : resetPassword && item == 'reset'
          ? !isValidEmail || !email
          : false
      "
      (click)="onPassBack(item)"
      type="button"
      [ngClass]="{
        'btn-primary': item == 'ok' || item == 'reset',
        widthButton: item == 'ok',
        'btn-danger':
          item == 'cancelRequest' ||
          item == 'discard' ||
          item == 'delete' ||
          item == 'decline',
        'btn-success':
          item == 'approve' || item == 'recover' || item === 'print',
        'btn-light': item == 'cancel',
        'decline-style': item == 'decline'
      }"
    >
      {{
        "DIALOG_MESSAGES." +
          (item == "cancelRequest"
            ? "CANCEL_REQUEST"
            : path == "pending" && item != "cancel" && item != "discard"
            ? item + "_REQUEST"
            : item)
          | uppercase
          | translate
      }}
    </button>
  </div>
</div>
