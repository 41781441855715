import { getContent, getContentByNode, getTemplates } from '@app/store/actions';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GoogleAnalyticsService } from './google-analytics.service';
import { selectDataBus, selectUser } from '../store/selectors';
import { environment } from 'environments/environment';
import { DateTimeService } from './date-time.service';
import { EventHubService } from './event-hub.service';
import { MappingService } from './mapping.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    DeleteUserModel,
    UpdateUserModel,
    UserModel,
} from '@app/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    isGetContentSuccess = false;
    lastModified: string;
    isMicrosite = false;
    saveSubscription;
    userToken: any;
    userRole: any;

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private dateTimeService: DateTimeService,
        private socialAuth: SocialAuthService,
        private mapService: MappingService,
        private eventHub: EventHubService,
        private authService: AuthService,
        private store: Store<any>,
        private http: HttpClient
    ) {
        const userState$ = this.store.select(selectUser);

        userState$.subscribe((userItem) => {
            this.userToken =
                userItem && userItem.userToken ? userItem.userToken : '';
            this.userRole =
                userItem && userItem.userRole ? userItem.userRole : '';
        });

        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem) => {
            this.lastModified =
                dataBusItem && dataBusItem.lastModified
                    ? dataBusItem.lastModified
                    : null;
        });

        this.socialAuth.authState.subscribe((user: SocialUser) => {
            if (!user) {
                return;
            }

            this.verifySSO(user);
        });
    }

    getApiCall(apiNode: string, useLastModified = false): Observable<any> {
        let headers: any = {
            'Content-Type': 'application/json',
            'x-api-key': `${environment.apiConfig.apiKey}`,
            Authorization: `Bearer ${this.userToken}`,
        };

        if (useLastModified) {
            headers = {
                ...headers,
                'if-modified-since': this.lastModified || '',
            };
        }

        return this.http
            .get<any>(`${environment.apiConfig.projectEndpoint}/${apiNode}`, {
                headers,
                observe: 'response',
                responseType: 'json',
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const str = error.status.toString();
                    if (
                        (str.substring(0, 2) === '40' || str === '0') &&
                        !this.isMicrosite
                    ) {
                        this.authService.signOut();
                    }
                    return throwError(error);
                })
            );
    }

    postApiCall(
        apiNode: string,
        apiBody: any,
        isDisabled?: boolean
    ): Observable<any> {
        let body = apiBody;

        if (
            apiNode === 'helpers/getVideoInfo' ||
            apiNode === 'getVideoInfo' ||
            apiNode === 'videos/getVideoInfo'
        ) {
            body = { videoURL: apiBody };
        } else if (apiNode === 'contents/saveContents') {
            body = {
                userToken: this.userToken,
                data: apiBody,
            };
        } else if (apiNode === 'contents/updateArrayContents') {
            body = {
                userToken: this.userToken,
                ...apiBody,
            };
        } else if (apiNode === 'users/addUser') {
            body = { user: new UserModel(apiBody) };
        } else if (apiNode === 'users/updateUser') {
            if (isDisabled !== undefined) {
                apiBody.disabled = isDisabled;
            }

            body = {
                user: new UpdateUserModel(apiBody),
            };
        } else if (apiNode === 'users/deleteUser') {
            body = new DeleteUserModel({
                userId: apiBody.uid,
            });
        } else if (apiNode === 'flightList') {
            body = apiBody;
        } else if (apiNode === 'getRequests') {
            body = {
                status: apiBody,
                fromDate: this.dateTimeService.getAddedDays(-90),
            };
        } else if (apiNode === 'getMatchingTemplate') {
            body = {
                arrivalAirportCode: apiBody.arrivalAirportCode,
                departureTime: apiBody.departureTime,
                equipment: apiBody.equipment,
            };
        }

        return this.http
            .post<any>(
                `${environment.apiConfig.projectEndpoint}/${apiNode}`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${environment.apiConfig.apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                        'user-role': `${this.userRole}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (apiNode === 'getMatchingTemplates') {
                        return throwError(error);
                    } else if (apiNode === 'GetFlightInventories') {
                        return throwError(error);
                    } else {
                        const str = error.status.toString();
                        if (str.substring(0, 2) === '40') {
                            this.authService.signOut();
                        }
                        return throwError(error);
                    }
                })
            );
    }

    putApiCall(apiNode: string, apiBody: any): Observable<any> {
        const body = apiBody;

        return this.http
            .put<any>(
                `${environment.apiConfig.projectEndpoint}/${apiNode}`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${environment.apiConfig.apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                        'user-role': `${this.userRole}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const str = error.status.toString();
                    if (str.substring(0, 2) === '40') {
                        this.authService.signOut();
                    }
                    return throwError(error);
                })
            );
    }

    postApiCallPromise(
        nodeApi: string,
        content: any,
        node?: string,
        additionalData?: any,
        triggerGetContentByNode = false
    ): any {
        let body = JSON.parse(JSON.stringify(content));
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }

        if (
            nodeApi === 'contents/saveContents' ||
            nodeApi === 'updateTemplate' ||
            nodeApi === 'notification/sendMessage' ||
            nodeApi === 'contents/updateArrayContents'
        ) {
            this.isGetContentSuccess = false;

            if (environment.appFlavor === 'lax') {
                body = this.mapService.mapLaxDataOnSaving(content, node);
            } else if (environment.appFlavor === 'lga') {
                body = this.mapService.mapLGASavingData(content, node);
            } else if (environment.appFlavor === 'neom') {
                body = this.mapService.mapNeomSavingData(content, node);
            } else if (environment.appFlavor === 'mea') {
                body = this.mapService.mapMeaSavingData(content, node);
            } else if (environment.appFlavor === 'mia') {
                body = this.mapService.mapMiaDataOnSaving(
                    content,
                    node,
                    nodeApi
                );
            } else if (environment.appFlavor === 'elpaso') {
                body = this.mapService.mapELPasoDataOnSaving(content, node);
            } else if (
                environment.appFlavor === 'bwa' ||
                environment.appFlavor === 'mvp' ||
                environment.appFlavor === 'universal' ||
                environment.appFlavor === 'afw'
            ) {
                body = this.mapService.mapAirlineAppDataOnSaving(
                    content,
                    node,
                    nodeApi
                );
            }
        }

        if (nodeApi === 'deleteTemplate') {
            return new Promise((resolve, reject) => {
                this.saveSubscription = this.putApiCall(
                    nodeApi,
                    body
                ).subscribe(
                    (response) => {
                        if (node === 'templates') {
                            this.store.dispatch(getTemplates());
                            resolve(this.isGetContentSuccess);
                        } else {
                            resolve(response);
                        }
                    },
                    (error) => {
                        const str = error.status.toString();
                        if (str.substring(0, 2) === '40') {
                            this.authService.signOut();
                        }
                        reject(error);
                    }
                );
            });
        } else {
            return new Promise((resolve, reject) => {
                this.saveSubscription = this.postApiCall(
                    nodeApi,
                    body
                ).subscribe(
                    (response) => {
                        if (
                            nodeApi === 'notification/sendMessage' &&
                            (environment.appFlavor === 'mia' ||
                                environment.appFlavor === 'mvp' ||
                                environment.appFlavor === 'bwa' ||
                                environment.appFlavor === 'universal' ||
                                environment.appFlavor === 'afw')
                        ) {
                            this.postApiCallPromise(
                                'contents/saveContents',
                                content,
                                node,
                                null,
                                triggerGetContentByNode
                            );

                            return;
                        }

                        if (
                            nodeApi === 'contents/saveContents' ||
                            nodeApi === 'contents/updateArrayContents'
                        ) {
                            if (response.status) {
                                this.store.dispatch(
                                    triggerGetContentByNode
                                        ? getContentByNode()
                                        : getContent()
                                );

                                resolve(true);
                            }
                        } else if (nodeApi === 'updateTemplate') {
                            if (response.status) {
                                this.store.dispatch(getTemplates());
                                resolve(response);
                            }
                        } else {
                            resolve(response);
                        }
                        if (
                            environment.appFlavor === 'mia' ||
                            environment.appFlavor === 'bwa' ||
                            environment.appFlavor === 'mvp' ||
                            environment.appFlavor === 'universal' ||
                            environment.appFlavor === 'afw'
                        ) {
                            this.eventHub.triggerSavingResult.emit(true);
                        }
                    },
                    (error) => {
                        if (nodeApi === 'getMatchingTemplates') {
                            reject(error);
                        } else {
                            const str = error.status.toString();
                            if (str.substring(0, 2) === '40') {
                                this.authService.signOut();
                            }
                            reject(error);
                        }
                    }
                );
            });
        }
    }

    verifySSO(user: SocialUser): void {
        this.authService.isLoading = true;
        this.authService.isSocialLogin = true;
        this.authService.loginLoading = true;

        this.http
            .post<any>(
                `${environment.apiConfig.projectEndpoint}/users/ssoVerify`,
                JSON.stringify({ idToken: user.idToken }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${environment.apiConfig.apiKey}`,
                    },
                }
            )
            .subscribe(
                (res) => {
                    if (res?.status || res?.data?.status) {
                        this.authService.credentialSignIn(user);
                    } else {
                        this.googleAnalyticService.onAgentStatusChange(
                            'failed',
                            user.email
                        );
                        //If the user role doesn't exist in the environment
                        this.authService.signOut();
                        this.authService.unauthorizedProvider = true;
                        this.authService.showAlert = true;
                        this.authService.isLoading = false;
                        this.authService.loginLoading = false;
                        setTimeout(() => {
                            this.authService.showAlert = false;
                            this.authService.unauthorizedProvider = false;
                        }, 10000);
                    }
                },
                (err) => {
                    this.googleAnalyticService.onAgentStatusChange(
                        'failed',
                        user.email
                    );
                    //If the user role doesn't exist in the environment
                    this.authService.signOut();
                    if (err.status === 405 || !err.error.status) {
                        this.authService.unauthorizedProvider = true;
                    }
                    this.authService.showAlert = true;
                    this.authService.isLoading = false;
                    this.authService.loginLoading = false;
                    setTimeout(() => {
                        this.authService.showAlert = false;
                        this.authService.unauthorizedProvider = false;
                    }, 10000);
                }
            );
    }
}
