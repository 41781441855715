export const environment = {
    pageName: 'MIA CMS',
    appFlavor: 'mia',
    production: true,
    appEnv: 'prod',
    googleMapsKey: 'AIzaSyARIsrg74iEf5gO2UOmZQcnEZVOqUFrP-Q',
    apiConfig: {
        projectEndpoint: 'https://mia-api.itxi.aero',
        apiKey: 'HpQr19Bo2k3912-zS6ZG2XKN-WEj0GIh',
    },
    firebase: {
        apiKey: 'AIzaSyARIsrg74iEf5gO2UOmZQcnEZVOqUFrP-Q',
        authDomain: 'sita-aca.firebaseapp.com',
        databaseURL: 'https://miami-airport.firebaseio.com',
        projectId: 'sita-aca',
        storageBucket: 'miami-airport',
        messagingSenderId: '643594054609',
        appId: '1:643594054609:web:b9873ab780094efcd88ef0',
        measurementId: 'G-6EN0EYQFZ3',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/airlines',
            node: 'airlines',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/airlines',
            node: 'airlines',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 25.7947591,
        longitude: -80.27876946,
        ISO3166_1_Alpha_2: 'LB',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId: '',
    signInOptions: ['password'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
