import { ConfigFormModel } from '@app/models/config-form.model';
import { createAction, props } from '@ngrx/store';
import {
    HomeBannerModel,
    AirlinesModel,
} from '@app/models/services/mappingModels';
import { types } from './types';

export const getContent = createAction(types.GET_CONTENT_ATTEMPT);

export const getContentSuccess = createAction(
    types.GET_CONTENT_SUCCESS,
    props<{
        background_categories: {};
        destinations: [];
        kioskNames: [];
        categories: [];
        transport: [];
        icons: [];
        pois: [];
        concourses: [];
        hotels: [];
        attractions: [];
        videos: [];
        idleState: [];
        category: [];
        pages: [];
        pagesOrdering: [];
        category_dropdown: [];
        emergency: {};
        emergencybroadcast: {};
        poiCategories: {}[];
        survey: [];
        survey_config: {};
        kioskname: [];
        distributionLists: [];
        specialUplift: [];
        mapElementIDs: string[];
        tags: {}[];
        airlines: AirlinesModel;
        parentPages: {}[];
        cities: {}[];
        config: ConfigFormModel;
        home_banners: HomeBannerModel;
        banners: [];
        offers: [];
        airports: [];
        lastModified: null;
        equipment: [];
        fares: [];
        terms: {};
        importantReminders: {};
        chatbotLogs: [];
        featuredCountries: [];
        fareFields: [];
        moreLinks: [];
        news: [];
        introSlides: [];
        flightNotifications: {};
        geofences: [];
        notificationViewCounts: {
            [key: string]: number;
        };
    }>()
);

export const setData = createAction(
    types.SET_DATA,
    props<{
        node: string;
        data: any;
    }>()
);

export const getContentFailed = createAction(
    types.GET_CONTENT_FAILED,
    props<{ isLastModified?: boolean }>()
);

export const getContentByNode = createAction(types.GET_CONTENT_BY_NODE_ATTEMPT);

export const setRowData = createAction(
    types.SET_ROW_DATA,
    props<{
        node: string;
        rowData: any;
        requestType?: string;
        index?: number;
        value?: string;
        key?: string;
    }>()
);

export const selectRowData = createAction(
    types.SELECT_ROW_DATA,
    props<{ node: string; rowData: {}; nodeType?: string }>()
);

export const updateNodeData = createAction(
    types.UPDATE_NODE_DATA,
    props<{ nodeData: any; requestType?: string; key?: string; value?: any }>()
);

export const setCategoryBgImage = createAction(
    types.SET_CATEGORY_BG_IMAGE,
    props<{ category: string; imageUrl: string }>()
);

export const setFilterValue = createAction(
    types.SET_FILTER_VALUE,
    props<{
        node: string;
        filterValue: string;
        rowIndex: number;
        pageNumber: number;
        sorting?: any;
    }>()
);

export const getAllUsers = createAction(types.GET_ALL_USERS_ATTEMPT);

export const getAllUsersSuccess = createAction(
    types.GET_ALL_USERS_SUCCESS,
    props<{
        users: {}[];
    }>()
);

export const getAllUsersFailed = createAction(
    types.GET_ALL_USERS_FAILED,
    props<{
        users: {}[];
    }>()
);

export const getRequest = createAction(
    types.GET_REQUEST_ATTEMPT,
    props<{ status: string; node: string }>()
);

export const getRequestSuccess = createAction(
    types.GET_REQUEST_SUCCESS,
    props<{
        node: string;
        apps: {}[];
    }>()
);

export const getRequestFailed = createAction(types.GET_REQUEST_FAILED);

export const getFlights = createAction(types.GET_FLIGHTS_ATTEMPT);

export const getFlightsSuccess = createAction(
    types.GET_FLIGHTS_SUCCESS,
    props<{
        airports: Array<{ key: string; label: string }>;
        flights: {}[];
    }>()
);

export const getFlightsFailed = createAction(types.GET_FLIGHTS_FAILED);

export const setAgents = createAction(
    types.SET_AGENTS,
    props<{
        agents: {}[];
    }>()
);

export const setCarts = createAction(
    types.SET_CARTS,
    props<{
        carts: {}[];
    }>()
);

export const getTemplates = createAction(types.GET_TEMPLATES_ATTEMPT);

export const getTemplatesSuccess = createAction(
    types.GET_TEMPLATES_SUCCESS,
    props<{
        templates: {}[];
    }>()
);

export const getTemplatesFailed = createAction(types.GET_TEMPLATES_FAILED);

export const setSelectedInventoryTemplate = createAction(
    types.SET_SELECTED_INVENTORY_Template,
    props<{
        selectedInventoryTemplate: any;
    }>()
);

export const getFlightInventories = createAction(
    types.GET_FLIGHT_INVENTORIES_ATTEMPT
);

export const getFlightInventoriesSuccess = createAction(
    types.GET_FLIGHT_INVENTORIES_SUCCESS,
    props<{
        inventories: {}[];
        inventoriesLastHours: string;
        inventoriesLastDays: string;
    }>()
);

export const getFlightInventoriesFailed = createAction(
    types.GET_FLIGHT_INVENTORIES_FAILED
);
