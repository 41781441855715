import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const setRTDBNode = createAction(
    types.SET_RTDB_NODE,
    props<{
        node: string;
        data: any;
    }>()
);
