import { GoogleAnalyticsService } from './google-analytics.service';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from './utility.service';
import * as timezone from 'dayjs/plugin/timezone';
import { Injectable } from '@angular/core';
import {
    MicrosoftLoginProvider,
    SocialAuthService,
    SocialUser,
} from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import * as utc from 'dayjs/plugin/utc';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import * as dayjs from 'dayjs';
import {
    setUserToken_And_LoginTime,
    resetUserToken,
    setFilterValue,
    selectRowData,
    setUserToken,
    getAllUsers,
} from '@app/store/actions';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    verifyPasswordResetCode,
    sendPasswordResetEmail,
    signInWithCredential,
    confirmPasswordReset,
    GoogleAuthProvider,
    signOut,
    getAuth,
} from 'firebase/auth';
import 'firebase/auth';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    resetPrerequisiteError = false;
    unauthorizedProvider = false;
    manyAttemptsError: boolean;
    roleConfigRoute: string;
    isLoadingUsers = false;
    isUserDisabled = false;
    isSocialLogin = false;
    loginLoading = false;
    isLoading = false;
    showAlert = false;
    isAgent = false;
    message = '';

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private socialAuth: SocialAuthService,
        private translate: TranslateService,
        private utility: UtilityService,
        private store: Store<any>,
        public router: Router
    ) {}

    async socialSignIn(): Promise<SocialUser | void> {
        this.isUserDisabled = false;
        this.isAgent = false;

        return await this.socialAuth
            .signIn(MicrosoftLoginProvider.PROVIDER_ID)
            .catch(() => {
                this.loginLoading = false;
                this.isLoadingUsers = false;
                this.showAlert = true;
                this.isLoading = false;
                setTimeout(() => {
                    this.showAlert = false;
                }, 10000);
            });
    }

    async signIn(email: string, password: string): Promise<any> {
        this.isUserDisabled = false;
        this.isAgent = false;

        const auth = await getAuth();

        await signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                result.user
                    .getIdTokenResult()
                    .then((tokenValue: any) => {
                        const currentTime = dayjs();
                        const originalTime = currentTime.clone();
                        this.googleAnalyticService.onAgentStatusChange(
                            'success',
                            email
                        );

                        const userRole = tokenValue.claims.role
                            ? tokenValue.claims.role.toLowerCase()
                            : '';
                        this.store.dispatch(
                            setUserToken({
                                userToken: tokenValue.token,
                                email: result.user.email,
                                userRole,
                                userLoginTime: originalTime['_d'],
                            })
                        );

                        this.roleConfigRoute = '';
                        const roleConfig =
                            environment.rolesWithLoginAccess.find(
                                (node) => node.role === userRole
                            );

                        if (roleConfig) {
                            if (userRole !== 'admin') {
                                this.loginLoading = false;
                                this.router.navigate([roleConfig.route]);
                            }

                            if (userRole === 'admin') {
                                this.store.dispatch(getAllUsers());
                                this.utility.isGetAllUsersApiCalled = true;
                            }

                            this.roleConfigRoute = roleConfig.route;

                            this.store.dispatch(
                                selectRowData({
                                    node: roleConfig.node.toLowerCase(),
                                    rowData: {},
                                })
                            );
                            this.isLoading = false;

                            this.store.dispatch(
                                setFilterValue({
                                    node: roleConfig.node,
                                    filterValue: '',
                                    rowIndex: null,
                                    pageNumber: 0,
                                })
                            );
                        } else {
                            //If the user role doesn't exist in the environment
                            this.isLoadingUsers = false;
                            this.signOut();
                            this.showAlert = true;
                            this.isLoading = false;
                            this.isAgent = true;
                            this.loginLoading = false;
                            setTimeout(() => {
                                this.showAlert = false;
                                this.isAgent = false;
                            }, 10000);
                        }
                    })
                    .catch(() => {
                        this.loginLoading = false;
                        this.googleAnalyticService.onAgentStatusChange(
                            'failed',
                            email
                        );
                        this.isLoadingUsers = false;
                        this.showAlert = true;
                        this.isLoading = false;
                        setTimeout(() => {
                            this.showAlert = false;
                        }, 10000);
                    });
            })
            .catch((error) => {
                this.loginLoading = false;
                switch (error.code) {
                    case 'auth/user-disabled':
                        this.isUserDisabled = true;
                        this.manyAttemptsError = false;
                        break;
                    case 'auth/too-many-requests':
                        this.isUserDisabled = false;
                        this.manyAttemptsError = true;
                        break;
                    default:
                        this.isUserDisabled = false;
                        break;
                }

                this.googleAnalyticService.onAgentStatusChange('failed', email);

                this.isLoadingUsers = false;
                this.showAlert = true;
                this.isLoading = false;
                setTimeout(() => {
                    this.showAlert = false;
                }, 10000);
            });
    }

    signOut(email?: string): void {
        if (email) {
            this.googleAnalyticService.onAgentStatusChange('logout', email);
        }
        this.isLoading = false;
        this.store.dispatch(resetUserToken());

        if (this.isSocialLogin) {
            this.socialAuth.signOut();
        } else {
            const auth = getAuth();
            signOut(auth);
        }

        this.isSocialLogin = false;

        this.router.navigate(['/login']);
    }

    // Sign up with email/password
    signUp(email: string, password: string): any {
        const auth = getAuth();
        return createUserWithEmailAndPassword(auth, email, password);
    }

    resetPassword(email: string): any {
        const auth = getAuth();
        return sendPasswordResetEmail(auth, email).catch((error) => {
            const str = error.status.toString();
            if (str.substring(0, 2) === '40') {
                this.signOut();
            }
        });
    }

    checkToken(): void {
        this.getCurrentUser()
            .then(() => {
                getAuth()
                    ?.currentUser?.getIdTokenResult(true)
                    .then((idTokenResult) => {
                        this.store.dispatch(
                            setUserToken_And_LoginTime({
                                userToken: idTokenResult.token,
                                userLoginTime: idTokenResult.issuedAtTime,
                            })
                        );
                    })
                    .catch(() => {
                        this.signOut();
                    });
            })
            .catch(() => {
                this.signOut();
            });
    }

    credentialSignIn(user: SocialUser): void {
        signInWithCredential(
            getAuth(),
            GoogleAuthProvider.credential(user.idToken)
        )
            .then((res: any) => {
                const currentTime = dayjs();
                const originalTime = currentTime.clone();
                this.googleAnalyticService.onAgentStatusChange(
                    'success',
                    user.email
                );

                const userInfo = JSON.parse(
                    res.user?.reloadUserInfo?.customAttributes
                );

                const userRole = userInfo ? userInfo.role : '';

                this.store.dispatch(
                    setUserToken({
                        userToken: res.user.accessToken,
                        email: user.email,
                        userRole,
                        userLoginTime: originalTime['_d'],
                        providerIDs: res.user.providerData.map(
                            (element) => element.providerId
                        ),
                    })
                );

                this.roleConfigRoute = '';
                const roleConfig = environment.rolesWithLoginAccess.find(
                    (node) => node.role === userRole
                );

                if (roleConfig) {
                    if (userRole !== 'admin') {
                        this.isLoadingUsers = false;
                        this.loginLoading = false;
                        this.router.navigate([roleConfig.route]);
                    }

                    if (userRole === 'admin') {
                        this.store.dispatch(getAllUsers());
                        this.utility.isGetAllUsersApiCalled = true;
                    }

                    this.roleConfigRoute = roleConfig.route;

                    this.store.dispatch(
                        selectRowData({
                            node: roleConfig.node.toLowerCase(),
                            rowData: {},
                        })
                    );
                    this.isLoading = false;

                    this.store.dispatch(
                        setFilterValue({
                            node: roleConfig.node,
                            filterValue: '',
                            rowIndex: null,
                            pageNumber: 0,
                        })
                    );
                } else {
                    this.googleAnalyticService.onAgentStatusChange(
                        'failed',
                        user.email
                    );
                    //If the user role doesn't exist in the environment
                    this.isLoadingUsers = false;
                    this.signOut();
                    this.showAlert = true;
                    this.isLoading = false;
                    this.loginLoading = false;
                    setTimeout(() => {
                        this.showAlert = false;
                    }, 10000);
                }
            })
            .catch((error) => {
                this.loginLoading = false;
                const str = error?.status?.toString();
                if (str.substring(0, 2) === '40') {
                    this.signOut();
                }
                return throwError(error);
            });
    }

    getCurrentUser(): any {
        return new Promise<any>((resolve, reject) => {
            getAuth()?.onAuthStateChanged(function (user) {
                if (user) {
                    resolve(user);
                } else {
                    reject('No user logged in');
                }
            });
        });
    }

    getParameterByName(name: string): any {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(
            window.location.search
        );
        if (match && decodeURIComponent(match[1].replace(/\+/g, ' '))) {
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        } else {
            return null;
        }
    }

    resetPasswordRequest(newPassword: string): void {
        this.message = '';

        //   // TODO: Implement getParameterByName()

        //   // Get the action to complete.
        var mode = this.getParameterByName('mode');

        // Get the one-time code from the query parameter.
        var actionCode = this.getParameterByName('oobCode');

        // (Optional) Get the continue URL from the query parameter if available.
        var continueUrl = this.getParameterByName('');

        // (Optional) Get the language code if available.
        var lang = this.getParameterByName('lang') || 'en';

        if (mode !== null && actionCode !== null) {
            // Handle the user management action.
            switch (mode) {
                case 'resetPassword':
                    // Display reset password handler and UI.
                    this.handleResetPassword(
                        actionCode,
                        continueUrl,
                        lang,
                        newPassword
                    );
                    break;
                default:
                // Error: invalid mode.
            }
        } else {
            this.resetPrerequisiteError = true;
            this.isLoading = false;
            this.message = this.translate.instant('FORMS.INVALID_RESET_URL');
        }
    }

    handleResetPassword(
        actionCode: any,
        continueUrl: string,
        lang: string,
        newPassword: string
    ): any {
        // Verify the password reset code is valid.

        const auth = getAuth();

        return verifyPasswordResetCode(auth, actionCode)
            .then(() => {
                confirmPasswordReset(auth, actionCode, newPassword)
                    .then(() => {
                        this.isLoading = false;
                        // Password reset has been confirmed and new password updated.
                        this.router.navigate(['/login']);
                    })
                    .catch(() => {
                        // Error occurred during confirmation. The code might have expired or the
                        // password is too weak.
                        this.message = this.translate.instant(
                            'FORMS.ERROR_OCCURRED_DURING_CONFIRMATION'
                        );
                        this.isLoading = false;
                        this.resetPrerequisiteError = false;
                        setTimeout(() => {
                            this.showAlert = false;
                        }, 1500);
                    });
            })
            .catch((error) => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                if (error.code === 'auth/network-request-failed') {
                    this.message = this.translate.instant(
                        'FORMS.CONNECTION_ERROR'
                    );
                } else {
                    this.message = this.translate.instant(
                        'FORMS.ACTION_CODE_IS_INVALID'
                    );
                }

                this.resetPrerequisiteError = true;
                this.isLoading = false;
                this.showAlert = false;
            });
    }
}
