import { UserState } from '../state/user.state';
import { createReducer, on } from '@ngrx/store';
import {
    setUserToken_And_LoginTime,
    setUserLastVisitedPage,
    resetUserToken,
    setUserToken,
} from '../actions/user.actions';

const initialState: UserState = {
    userToken: '',
    email: '',
    userLoginTime: '',
    userRole: '',
    lastVisitedPage: '',
    providerIDs: [],
};

const userReducer = createReducer(
    initialState,
    on(setUserToken, (state, action) => {
        const _u = { ...state };
        _u.userToken = action.userToken;
        _u.email = action.email ? action.email : '';
        _u.userLoginTime = action.userLoginTime;
        _u.userRole = action.userRole;
        _u.providerIDs = action.providerIDs;
        return _u;
    }),
    on(resetUserToken, (state) => {
        const _u = { ...state };
        _u.userToken = '';
        _u.userLoginTime = '';
        return _u;
    }),
    on(setUserToken_And_LoginTime, (state, action) => {
        const _u = { ...state };
        _u.userToken = action.userToken;
        _u.userLoginTime = action.userLoginTime;
        return _u;
    }),
    on(setUserLastVisitedPage, (state, action) => {
        const _u = { ...state };
        _u.lastVisitedPage = action.lastVisitedPage;

        return _u;
    })
);

export default function UserReducer(state: any, action: any): any {
    return userReducer(state, action);
}
