import dataBusReducer from './dataBus.reducer';
import rtdbReducer from './rtdb.reducer';
import userReducer from './user.reducer';
import uiReducer from './ui.reducer';

export const combinedReducers = {
    dataBus: dataBusReducer,
    rtdb: rtdbReducer,
    user: userReducer,
    ui: uiReducer,
};
