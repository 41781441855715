import { ContentAlignmentEnum, MaskTypesEnum } from '../enums/introSlidesEnum';
import { OfferTripTypeEnum } from '../enums/offerTripTypeEnum';
import { PageCategoryEnum } from '../enums/pageCategoryEnum';
import * as _ from 'lodash';

export class KioskNameDataModel {
    [key: string]: KioskName;
}

export class KioskName {
    computer_name?: string;
    map_element_id?: string;
    orientation?: string;
    landside?: boolean;

    constructor(props: {
        computer_name?: string;
        map_element_id?: string;
        orientation?: string;
        landside?: boolean;
    }) {
        return {
            computer_name: props.computer_name || '',
            map_element_id: props.map_element_id || '',
            orientation: props.orientation || '',
            landside: props.landside || false,
        };
    }
}

export class KioskNameLAXDataModel {
    [key: string]: KioskNameLAX;
}

export class KioskNameLAX {
    computerName?: string;
    kioskLocation?: string;
    poiID?: number;

    constructor(props: {
        computerName?: string;
        kioskLocation?: string;
        poiID?: number;
    }) {
        return {
            computerName: props.computerName || '',
            kioskLocation: props.kioskLocation || '',
            poiID: props.poiID || null,
        };
    }
}

export class Categories {
    category?: string;
    map_element_id?: string;

    constructor(props: { category?: string; map_element_id?: string }) {
        return {
            category: props.category || '',
            map_element_id: props.map_element_id || '',
        };
    }
}

export class MappedCategories {
    category?: string;
    map_element_id?: Array<string>;

    constructor(props: { category?: string; map_element_id?: Array<string> }) {
        return {
            category: props.category || '',
            map_element_id: props.map_element_id || [],
        };
    }
}

export class Concourses {
    display_text?: string;
    gates?: Array<string> | string;
    map_element_id?: string;

    constructor(props: {
        display_text?: string;
        gates?: Array<string>;
        map_element_id?: string;
    }) {
        return {
            display_text: props.display_text || '',
            gates: props.gates || [],
            map_element_id: props.map_element_id || '',
        };
    }
}

export class HotelAndAttraction {
    address?: string;
    description?:
        | {
              en?: string;
              es?: string;
          }
        | string;
    id?: number;
    imageURL?: string;
    isFeatured?: boolean;
    location?: string;
    name?: string;
    phoneNumber?: string;
    placeID?: string;
    rating?: number;
    websiteURL?: string;

    constructor(props: {
        address?: string;
        description?:
            | {
                  en?: string;
                  es?: string;
              }
            | any;
        id?: number;
        imageURL?: string;
        isFeatured?: boolean;
        location?: string;
        name?: string;
        phoneNumber?: string;
        placeID?: string;
        rating?: number;
        websiteURL?: string;
    }) {
        return {
            address: props.address || '',
            description: {
                en:
                    props.description && props.description.hasOwnProperty('en')
                        ? props.description.en || ''
                        : props.description || '',
                es:
                    props.description && props.description.hasOwnProperty('es')
                        ? props.description.es || ''
                        : props.description || '',
            },
            id: props.id || null,
            imageURL: props.imageURL || '',
            isFeatured: props.isFeatured,
            location: props.location || '',
            name: props.name || '',
            phoneNumber: props.phoneNumber || '',
            placeID: props.placeID || '',
            rating: props.rating || 0,
            websiteURL: props.websiteURL || '',
        };
    }
}

export class UserDataModel {
    [key: string]: User;
}

export class User {
    creationTime?: string;
    disabled?: boolean;
    email?: string;
    firstName?: string;
    languages?: Array<string> | string;
    lastName?: string;
    lastSignInTime?: string;
    role?: string;
    providerIDs?: Array<string>;

    constructor(props: {
        creationTime?: string;
        disabled?: boolean;
        email?: string;
        firstName?: string;
        languages?: Array<string> | string;
        lastName?: string;
        lastSignInTime?: string;
        role?: string;
        providerIDs?: Array<string>;
    }) {
        return {
            creationTime: props.creationTime || '',
            disabled: props.disabled,
            email: props.email || '',
            firstName: props.firstName || '',
            languages: props.languages || '',
            lastName: props.lastName || '',
            lastSignInTime: props.lastSignInTime || '',
            role: props.role || '',
            providerIDs: props.providerIDs || [],
        };
    }
}

export class MappedUser {
    creationTime?: string;
    disabled?: boolean;
    email?: string;
    firstName?: string;
    languages?: string;
    lastName?: string;
    lastSignInTime?: string;
    role?: string;
    uid?: string;
    providerIDs?: Array<string>;

    constructor(props?: {
        creationTime?: string;
        disabled?: boolean;
        email?: string;
        firstName?: string;
        languages?: string;
        lastName?: string;
        lastSignInTime?: string;
        role?: string;
        uid?: string;
        providerIDs?: Array<string>;
    }) {
        return {
            creationTime: props?.creationTime || '',
            disabled: props?.disabled,
            email: props?.email || '',
            firstName: props?.firstName || '',
            languages: props?.languages || '',
            lastName: props?.lastName || '',
            lastSignInTime: props?.lastSignInTime || null,
            role: props?.role || '',
            uid: props?.uid || '',
            providerIDs: props?.providerIDs || [],
        };
    }
}

export class VideoModel {
    categoryName?: string;
    videos?: Array<Video>;

    constructor(props: { categoryName?: string; videos?: Array<Video> }) {
        return {
            categoryName: props.categoryName || '',
            videos: props.videos && props.videos.length > 0 ? props.videos : [],
        };
    }
}

export class Video {
    categoryName?: string;
    featured?: boolean;
    id?: number;
    thumbnailURL?: string;
    type?: string;
    videoId?: string;
    videoTitle?: string;
    videoURL?: string;
    isDisabled?: boolean;

    constructor(props: {
        categoryName?: string;
        featured?: boolean;
        id?: number;
        thumbnailURL?: string;
        type?: string;
        videoId?: string;
        videoTitle?: string;
        videoURL?: string;
        isDisabled?: boolean;
    }) {
        return {
            categoryName: props.categoryName || '',
            featured: props.featured,
            id: props.id || null,
            thumbnailURL: props.thumbnailURL || '',
            type: props.type || '',
            videoId: props.videoId || '',
            videoTitle: props.videoTitle || '',
            videoURL: props.videoURL || '',
            isDisabled: props.hasOwnProperty('isDisabled')
                ? props.isDisabled
                : false,
        };
    }
}

export class Category {
    id?: number;
    parentCategory?: {
        en?: string;
        es?: string;
    };
    subCategories?: Array<SubCategory>;

    constructor(props: {
        id?: number;
        parentCategory?: {
            en?: string;
            es?: string;
        };
        subCategories?: Array<SubCategory>;
    }) {
        return {
            id: props.id || null,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            subCategories: props.subCategories || [],
        };
    }
}

export class SubCategory {
    body?: {
        en?: string;
        es?: string;
    };
    category?: { en: string; es: string };
    id?: number;
    sections?: Array<Section>;

    constructor(props: {
        body?: {
            en?: string;
            es?: string;
        };
        category?: { en: string; es: string };
        id?: number;
        sections?: Array<Section>;
    }) {
        return {
            body: {
                en: props.body && props.body.en ? props.body.en : '',
                es: props.body && props.body.es ? props.body.es : '',
            },
            category: {
                en:
                    props.category && props.category.en
                        ? props.category.en
                        : '',
                es:
                    props.category && props.category.es
                        ? props.category.es
                        : '',
            },
            id: props.id || null,
            sections: props.sections || [],
        };
    }
}

export class Section {
    title?: { en?: string; es?: string };
    body?: { en?: string; es?: string };
    id?: number;

    constructor(props: {
        title?: { en?: string; es?: string };
        body?: { en?: string; es?: string };
        id?: number;
    }) {
        return {
            title: {
                en: props.title && props.title.es ? props.title.es : '',
                es: props.title && props.title.es ? props.title.es : '',
            },
            body: {
                en: props.body && props.body.es ? props.body.es : '',
                es: props.body && props.body.es ? props.body.es : '',
            },
            id: props.id || null,
        };
    }
}

export class MappedCategory {
    body?: {
        en?: string;
        es?: string;
    };
    category?: {
        en?: string;
        es?: string;
    };
    categoryID?: number;
    isMainCategory?: boolean;
    parentCategory?: {
        en?: string;
        es?: string;
    };
    parentCategoryID?: number;

    constructor(props: {
        body?: {
            en?: string;
            es?: string;
        };
        category?: {
            en?: string;
            es?: string;
        };
        categoryID?: number;
        isMainCategory?: boolean;
        parentCategory?: {
            en?: string;
            es?: string;
        };
        parentCategoryID?: number;
    }) {
        return {
            body: {
                en: props.body && props.body.en ? props.body.en : '',
                es: props.body && props.body.en ? props.body.es : '',
            },
            category: {
                en:
                    props.category && props.category.en
                        ? props.category.en
                        : '',
                es:
                    props.category && props.category.es
                        ? props.category.es
                        : '',
            },
            categoryID: props.categoryID || null,
            isMainCategory: props.isMainCategory,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            parentCategoryID: props.parentCategoryID || null,
        };
    }
}

export class MappedPage {
    body?: {
        en?: string;
        es?: string;
    };
    category?: {
        en?: string;
        es?: string;
    };
    categoryID?: number;
    id?: number;
    parentCategory?: {
        en?: string;
        es?: string;
    };
    parentCategoryID?: number;
    title?: {
        en?: string;
        es?: string;
    };

    constructor(props: {
        body?: {
            en?: string;
            es?: string;
        };
        category?: {
            en?: string;
            es?: string;
        };
        categoryID?: number;
        id?: number;
        parentCategory?: {
            en?: string;
            es?: string;
        };
        parentCategoryID?: number;
        title?: {
            en?: string;
            es?: string;
        };
    }) {
        return {
            body: {
                en: props.body && props.body.en ? props.body.en : '',
                es: props.body && props.body.en ? props.body.es : '',
            },
            category: {
                en:
                    props.category && props.category.en
                        ? props.category.en
                        : '',
                es:
                    props.category && props.category.es
                        ? props.category.es
                        : '',
            },
            categoryID: props.categoryID || null,
            id: props.id || null,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            parentCategoryID: props.parentCategoryID || null,
            title: {
                en: props.title && props.title.en ? props.title.en : '',
                es: props.title && props.title.en ? props.title.es : '',
            },
        };
    }
}

export class MappedMiaPage {
    description?: {
        [key: string]: string;
    };
    title?: {
        [key: string]: string;
    };
    parentPage?: string;
    hidden?: boolean;
    isParentPage?: boolean;
    parentPageTitleEN?: string;
    parentPageID?: string;
    pageID?: string;
    pageTitleEn?: string;
    banner?: string;
    icon?: string;
    actions?: Array<PageActionsModel>;
    index?: number;

    constructor(props: MappedMiaPage) {
        const descriptionObj = {};
        const titleObj = {};
        copyProps(props.description, descriptionObj);
        copyProps(props.title, titleObj);

        return {
            description: descriptionObj,
            title: titleObj,
            parentPage: props.parentPage || null,
            hidden: props.hidden || false,
            isParentPage: props.parentPage ? false : true,
            parentPageTitleEN: props.parentPageTitleEN
                ? props.parentPageTitleEN
                : '',
            parentPageID: props.parentPageID || '',
            pageID: props.pageID || '',
            pageTitleEn: props.title && props.title.en ? props.title.en : '',
            banner: props.banner || '',
            icon: props.icon || '',
            actions: props?.actions || [],
            index: props?.index || 0,
        };
    }
}

export class NormalizeMiaPage {
    description?: {
        [key: string]: string;
    };
    title?: {
        [key: string]: string;
    };
    parentPage?: string;
    hidden?: boolean;
    isParentPage?: boolean;
    parentPageTitleEN?: string;
    parentPageID?: string;
    pageID?: string;
    pageTitleEn?: string;
    banner?: string;
    icon?: string;
    actions?: Array<PageActionsModel>;

    constructor(props: NormalizeMiaPage) {
        const descriptionObj = {};
        const titleObj = {};
        copyProps(props.description, descriptionObj);
        copyProps(props.title, titleObj);

        return {
            description: descriptionObj,
            title: titleObj,
            parentPage:
                props.parentPage && !props.isParentPage ? props.parentPage : '',
            hidden: props.hidden || false,
            actions: props?.actions || [],
        };
    }
}

export class PageActionsModel {
    type: string;
    label: {
        [key: string]: string;
    };
    value?: string;
    lat?: string;
    lng?: string;

    constructor(props: PageActionsModel) {
        const labelObj = {};
        copyProps(props.label, labelObj);

        const obj = {
            type: props?.type || '',
            label: labelObj,
        };

        if (props?.type === 'worldMap') {
            obj['lat'] = props?.lat || '';
            obj['lng'] = props?.lng || '';
        } else {
            obj['value'] = props?.value || '';
        }

        return obj;
    }
}

export class PageActionsAirlineAppModel {
    type: string;
    value?: string;
    content?: string;

    constructor(props: PageActionsAirlineAppModel) {
        return {
            type: props?.type || '',
            content: props?.value || props.content || '',
        };
    }
}

export class Survey {
    persistentSurvey?: {
        enabled?: boolean;
        introScreen?: {
            en?: string;
            es?: string;
        };
        questions?: Array<{
            en?: string;
            es?: string;
        }>;
        thankYouScreen?: {
            en?: string;
            es?: string;
        };
    };
    postCallSurvey?: {
        enabled?: boolean;
        introScreen?: {
            en?: string;
            es?: string;
        };
        questions?: Array<{
            en?: string;
            es?: string;
        }>;
        thankYouScreen?: {
            en?: string;
            es?: string;
        };
    };
    constructor(props: {
        persistentSurvey?: {
            enabled?: boolean;
            introScreen?: {
                en?: string;
                es?: string;
            };
            questions?: Array<{
                en?: string;
                es?: string;
            }>;
            thankYouScreen?: {
                en?: string;
                es?: string;
            };
        };
        postCallSurvey?: {
            enabled?: boolean;
            introScreen?: {
                en?: string;
                es?: string;
            };
            questions?: Array<{
                en?: string;
                es?: string;
            }>;
            thankYouScreen?: {
                en?: string;
                es?: string;
            };
        };
    }) {
        return {
            persistentSurvey: {
                enabled:
                    props.persistentSurvey && props.persistentSurvey.enabled
                        ? props.persistentSurvey.enabled
                        : false,
                introScreen: {
                    en:
                        props.persistentSurvey &&
                        props.persistentSurvey.introScreen &&
                        props.persistentSurvey.introScreen.en
                            ? props.persistentSurvey.introScreen.en
                            : '',
                    es:
                        props.persistentSurvey &&
                        props.persistentSurvey.introScreen &&
                        props.persistentSurvey.introScreen.es
                            ? props.persistentSurvey.introScreen.es
                            : '',
                },
                questions:
                    props.persistentSurvey && props.persistentSurvey.questions
                        ? props.persistentSurvey.questions
                        : [],
                thankYouScreen: {
                    en:
                        props.persistentSurvey &&
                        props.persistentSurvey.thankYouScreen &&
                        props.persistentSurvey.thankYouScreen.en
                            ? props.persistentSurvey.thankYouScreen.en
                            : '',
                    es:
                        props.persistentSurvey &&
                        props.persistentSurvey.thankYouScreen &&
                        props.persistentSurvey.thankYouScreen.es
                            ? props.persistentSurvey.thankYouScreen.es
                            : '',
                },
            },
            postCallSurvey: {
                enabled:
                    props.postCallSurvey && props.postCallSurvey.enabled
                        ? props.postCallSurvey.enabled
                        : false,
                introScreen: {
                    en:
                        props.postCallSurvey &&
                        props.postCallSurvey.introScreen &&
                        props.postCallSurvey.introScreen.en
                            ? props.postCallSurvey.introScreen.en
                            : '',
                    es:
                        props.postCallSurvey &&
                        props.postCallSurvey.introScreen &&
                        props.postCallSurvey.introScreen.es
                            ? props.postCallSurvey.introScreen.es
                            : '',
                },
                questions:
                    props.postCallSurvey && props.postCallSurvey.questions
                        ? props.postCallSurvey.questions
                        : [],
                thankYouScreen: {
                    en:
                        props.postCallSurvey &&
                        props.postCallSurvey.thankYouScreen &&
                        props.postCallSurvey.thankYouScreen.en
                            ? props.postCallSurvey.thankYouScreen.en
                            : '',
                    es:
                        props.postCallSurvey &&
                        props.postCallSurvey.thankYouScreen &&
                        props.postCallSurvey.thankYouScreen.es
                            ? props.postCallSurvey.thankYouScreen.es
                            : '',
                },
            },
        };
    }
}

export class MappedSurvey {
    question?: { en?: string; es?: string };
    type?: string;

    constructor(props: {
        question?: { en?: string; es?: string };
        type?: string;
    }) {
        return {
            question: {
                en:
                    props.question && props.question.en
                        ? props.question.en
                        : '',
                es:
                    props.question && props.question.es
                        ? props.question.es
                        : '',
            },
            type: props.type || '',
        };
    }
}

export class MappedCategoryDropDown {
    parentCategory?: { en?: string; es?: string };
    parentCategoryID?: number;
    subCategories?: Array<SubCategoryDropDown>;

    constructor(props: {
        parentCategory?: { en?: string; es?: string };
        parentCategoryID?: number;
        subCategories?: Array<SubCategoryDropDown>;
    }) {
        return {
            parentCategoryID: props.parentCategoryID || null,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            subCategories: props.subCategories || [],
        };
    }
}

export class SubCategoryDropDown {
    body?: {
        en?: string;
        es?: string;
    };
    category?: { en?: string; es?: string };
    categoryID?: number;
    parentCategory?: { en?: string; es?: string };
    parentCategoryID?: number;

    constructor(props: {
        body?: {
            en?: string;
            es?: string;
        };
        category?: { en?: string; es?: string };
        categoryID?: number;
        parentCategory?: { en?: string; es?: string };
        parentCategoryID?: number;
    }) {
        return {
            body: {
                en: props.body && props.body.en ? props.body.en : '',
                es: props.body && props.body.es ? props.body.es : '',
            },
            category: {
                en:
                    props.category && props.category.en
                        ? props.category.en
                        : '',
                es:
                    props.category && props.category.es
                        ? props.category.es
                        : '',
            },
            categoryID: props.categoryID || null,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            parentCategoryID: props.parentCategoryID || null,
        };
    }
}

export class NormalizeVideosDataModel {
    [key: string]: Array<Video>;
}

export class NormalizeVideo {
    categoryName?: string;
    videos?: Array<Video>;

    constructor(props: { categoryName?: string; videos?: Array<Video> }) {
        return {
            categoryName: props.categoryName || '',
            videos:
                props.videos &&
                Array.isArray(props.videos) &&
                props.videos.length > 0
                    ? props.videos
                    : [],
        };
    }
}

export class NormalizeCategoryModel {
    [key: string]: Array<NormalizeCategory>;
}

export class NormalizeCategory {
    body?: {
        en?: string;
        es?: string;
    };
    category?: {
        en?: string;
        es?: string;
    };
    categoryID?: number;
    isMainCategory?: boolean;
    parentCategory?: {
        en?: string;
        es?: string;
    };
    parentCategoryID?: number;
    id?: number;

    constructor(props: {
        body?: {
            en?: string;
            es?: string;
        };
        category?: {
            en?: string;
            es?: string;
        };
        categoryID?: number;
        isMainCategory?: boolean;
        parentCategory?: {
            en?: string;
            es?: string;
        };
        parentCategoryID?: number;
        id?: number;
    }) {
        return {
            body: {
                en: props.body && props.body.en ? props.body.en : '',
                es: props.body && props.body.en ? props.body.es : '',
            },
            category: {
                en:
                    props.category && props.category.en
                        ? props.category.en
                        : '',
                es:
                    props.category && props.category.es
                        ? props.category.es
                        : '',
            },
            categoryID: props.categoryID || null,
            isMainCategory: props.isMainCategory,
            parentCategory: {
                en:
                    props.parentCategory && props.parentCategory.en
                        ? props.parentCategory.en
                        : '',
                es:
                    props.parentCategory && props.parentCategory.es
                        ? props.parentCategory.es
                        : '',
            },
            parentCategoryID: props.parentCategoryID || null,
            id: props.id || null,
        };
    }
}

export class DataModel {
    background_categories?: {};
    destinations?: {}[];
    kioskNames?: Array<any>;
    categories?: {}[];
    transport?: {}[];
    icons?: Array<any>;
    pois?: {}[];
    concourses?: {}[];
    users?: {}[];
    hotels?: {}[];
    laxpois?: {}[];
    attractions?: {}[];
    videos?: {}[];
    category?: {}[];
    pages?: {}[];
    category_dropdown?: {}[];
    emergencybroadcast?: {};
    survey?: {}[];
    survey_config?: {};
    kioskname?: {}[];
    distributionLists?: {}[];
    specialUplift?: Array<string>;
    flights?: {}[];
    templates?: {}[];
    tags?: {}[];
    poiCategories?: {}[];
    airlines?: AirlinesModel;
    cities?: {}[];

    constructor(props: {
        background_categories?: {};
        destinations?: {}[];
        kioskNames?: Array<any>;
        categories?: {}[];
        transport?: {}[];
        icons?: Array<any>;
        pois?: {}[];
        concourses?: {}[];
        users?: {}[];
        hotels?: {}[];
        laxpois?: {}[];
        attractions?: {}[];
        videos?: {}[];
        category?: {}[];
        pages?: {}[];
        category_dropdown?: {}[];
        emergencybroadcast?: {};
        survey?: {}[];
        survey_config?: {};
        kioskname?: {}[];
        distributionLists?: {}[];
        specialUplift?: [];
        flights?: {}[];
        templates?: {}[];
        tags?: {}[];
        poiCategories?: {}[];
        airlines?: AirlinesModel;
        cities: {}[];
    }) {
        return {
            background_categories: props.background_categories || {},
            destinations: props.destinations || [],
            kioskNames: props.kioskNames || [],
            categories: props.categories || [],
            transport: props.transport || [],
            icons: props.icons || [],
            pois: props.pois || [],
            concourses: props.concourses || [],
            users: props.users || [],
            hotels: props.hotels || [],
            laxpois: props.laxpois || [],
            attractions: props.attractions || [],
            videos: props.videos || [],
            category: props.category || [],
            pages: props.pages || [],
            category_dropdown: props.category_dropdown || [],
            emergencybroadcast: props.emergencybroadcast || {},
            survey: props.survey || [],
            survey_config: props.survey_config || {},
            kioskname: props.kioskname || [],
            distributionLists: props.distributionLists || [],
            specialUplift: props.specialUplift || [],
            flights: props.flights || [],
            templates: props.templates || [],
            tags: props.tags || [],
            poiCategories: props.poiCategories || [],
            airlines: props.airlines || {},
            cities: props.cities || [],
        };
    }
}

export class DistributionList {
    preOrderSaleableItems?: {
        distributionList?: Array<string>;
    };
    specialUplift?: {
        config?: {
            options?: Array<string>;
        };
        distributionList?: Array<string>;
    };

    constructor(props: {
        preOrderSaleableItems?: {
            distributionList?: Array<string>;
        };
        specialUplift?: {
            config?: {
                options?: Array<string>;
            };
            distributionList?: Array<string>;
        };
    }) {
        return {
            preOrderSaleableItems: {
                distributionList:
                    props.preOrderSaleableItems &&
                    props.preOrderSaleableItems.distributionList &&
                    Array.isArray(
                        props.preOrderSaleableItems.distributionList
                    ) &&
                    props.preOrderSaleableItems.distributionList.length > 0
                        ? props.preOrderSaleableItems.distributionList
                        : [],
            },
            specialUplift: {
                config: {
                    options:
                        props.specialUplift &&
                        props.specialUplift.config &&
                        props.specialUplift.config.options &&
                        Array.isArray(props.specialUplift.config.options) &&
                        props.specialUplift.config.options.length > 0
                            ? props.specialUplift.config.options
                            : [],
                },
                distributionList:
                    props.specialUplift &&
                    props.specialUplift.distributionList &&
                    Array.isArray(props.specialUplift.distributionList) &&
                    props.specialUplift.distributionList.length > 0
                        ? props.specialUplift.distributionList
                        : [],
            },
        };
    }
}

export class MappedDistributionList {
    dbNode: string;
    distributionListName: string;
    emails: Array<string>;

    constructor(props: {
        dbNode: string;
        distributionListName: string;
        emails: Array<string>;
    }) {
        return {
            dbNode: props.dbNode || '',
            distributionListName: props.distributionListName || '',
            emails:
                props.emails &&
                Array.isArray(props.emails) &&
                props.emails.length > 0
                    ? props.emails
                    : [],
        };
    }
}

export class Flight {
    arrivalAirportCode?: string;
    departureAirportCode?: string;
    flightNumber?: string;
    flightUuid?: string;
    localDepartureDate?: string;
    operatingAirline?: string;
    depScheduled?: string;
    equipment?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];

    constructor(props: Flight) {
        return {
            arrivalAirportCode: props.arrivalAirportCode || '',
            departureAirportCode: props.departureAirportCode || '',
            flightNumber: props.flightNumber || '',
            flightUuid: props.flightUuid || '',
            localDepartureDate: props.localDepartureDate || '',
            operatingAirline: props.operatingAirline || '',
            depScheduled: props.depScheduled ? props.depScheduled : '',
            equipment: props.equipment ? props.equipment : '',
            regNumber: props.regNumber ? props.regNumber : '',
            associatedFlight:
                props.associatedFlight &&
                Array.isArray(props.associatedFlight) &&
                props.associatedFlight.length > 0
                    ? props.associatedFlight
                    : [],
        };
    }
}

export class MappedFlight {
    flightNum?: string;
    flightNumber?: string;
    flightUUID?: string;
    flightValue?: string;
    localDepartureDate?: string;
    operatingAirline?: string;
    departureTime?: string;
    equipment?: string;
    arrivalAirportCode?: string;
    departureAirportCode?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];

    constructor(props: MappedFlight) {
        return {
            flightNum: props.flightNum || '',
            flightNumber: props.flightNumber || '',
            flightUUID: props.flightUUID || null,
            flightValue: props.flightValue || '',
            localDepartureDate: props.localDepartureDate || '',
            operatingAirline: props.operatingAirline || '',
            departureTime: props.departureTime ? props.departureTime : '',
            equipment: props.equipment ? props.equipment : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            regNumber: props.regNumber ? props.regNumber : '',
            associatedFlight: props.associatedFlight
                ? props.associatedFlight
                : [],
        };
    }
}

export class AssociatedFlight {
    flightUuid?: string;
    depScheduled?: string;
    departureAirportCode?: string;
    arrivalAirportCode?: string;
    departureTime?: string;
    flightNumber?: string;
    operatingAirline?: string;
    flightInfo?: string;
    localDepartureDate?: string;
    regNumber?: string;

    constructor(props: AssociatedFlight) {
        return {
            flightUuid: props.flightUuid ? props.flightUuid : '',
            depScheduled: props.depScheduled ? props.depScheduled : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            departureTime: props.departureTime ? props.departureTime : '',
            flightNumber: props.flightNumber ? props.flightNumber : '',
            operatingAirline: props.operatingAirline
                ? props.operatingAirline
                : '',
            flightInfo: props.flightInfo ? props.flightInfo : '',
            localDepartureDate: props.localDepartureDate
                ? props.localDepartureDate
                : '',
            regNumber: props.regNumber ? props.regNumber : '',
        };
    }
}

export class AssociatedFlightModel {
    flightUuid?: string;
    depScheduled?: string;
    departureAirportCode?: string;
    arrivalAirportCode?: string;
    flightNumber?: string;
    operatingAirline?: string;
    localDepartureDate?: string;
    regNumber?: string;

    constructor(props: AssociatedFlightModel) {
        return {
            flightUuid: props.flightUuid ? props.flightUuid : '',
            depScheduled: props.depScheduled ? props.depScheduled : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            flightNumber: props.flightNumber ? props.flightNumber : '',
            operatingAirline: props.operatingAirline
                ? props.operatingAirline
                : '',
            localDepartureDate: props.localDepartureDate
                ? props.localDepartureDate
                : '',
            regNumber: props.regNumber ? props.regNumber : '',
        };
    }
}

export class Requests {
    activity?: Array<Activity>;
    flightNumber?: string;
    flightUUID?: string;
    localDepartureDate?: string;
    requestContents?: {
        item?: string;
        passengerName?: string;
        requestType?: string;
        requestedBy?: string;
        specialUplift?: Array<string>;
    };
    requestUUID?: string;
    status?: string;
    timestamp?: string;

    constructor(props: {
        activity?: Array<Activity>;
        flightNumber?: string;
        flightUUID?: string;
        localDepartureDate?: string;
        requestContents?: {
            item?: string;
            passengerName?: string;
            requestType?: string;
            requestedBy?: string;
            specialUplift?: Array<string>;
        };
        requestUUID?: string;
        status?: string;
        timestamp?: string;
    }) {
        return {
            activity:
                props.activity &&
                Array.isArray(props.activity) &&
                props.activity.length > 0
                    ? props.activity
                    : [],
            flightNumber: props.flightNumber || '',
            flightUUID: props.flightUUID || '',
            localDepartureDate: props.localDepartureDate || '',
            requestContents: {
                item:
                    props.requestContents && props.requestContents.item
                        ? props.requestContents.item
                        : '',
                passengerName:
                    props.requestContents && props.requestContents.passengerName
                        ? props.requestContents.passengerName
                        : '',
                requestType:
                    props.requestContents && props.requestContents.requestType
                        ? props.requestContents.requestType
                        : '',
                requestedBy:
                    props.requestContents && props.requestContents.requestedBy
                        ? props.requestContents.requestedBy
                        : '',
                specialUplift:
                    props.requestContents &&
                    props.requestContents.specialUplift &&
                    Array.isArray(props.requestContents.specialUplift) &&
                    props.requestContents.specialUplift.length > 0
                        ? props.requestContents.specialUplift
                        : [],
            },
            requestUUID: props.requestUUID || '',
            status: props.status || '',
            timestamp: props.timestamp || '',
        };
    }
}

export class Activity {
    action?: string;
    activityDate?: string;
    who?: string;
    reason?: string;

    constructor(props: {
        action?: string;
        activityDate?: string;
        who?: string;
        reason?: string;
    }) {
        return {
            action: props.action || '',
            activityDate: props.activityDate || '',
            who: props.who || '',
            reason: props.reason || '',
        };
    }
}

export class MappedRequest {
    activity?: Array<MappedActivity>;
    flightNumber?: string;
    flightUUID?: string;
    localDepartureDate?: string;
    requestContents?: {
        item?: string;
        passengerName?: string;
        paymentMethod?: string;
        paymentStatus?: string;
        requestType?: string;
        requestedBy?: string;
        specialUplift?: Array<string>;
    };
    requestUUID?: string;
    status?: string;
    timestamp?: string;

    constructor(props: {
        activity?: Array<MappedActivity>;
        flightNumber?: string;
        flightUUID?: string;
        localDepartureDate?: string;
        requestContents?: {
            item?: string;
            passengerName?: string;
            paymentMethod?: string;
            paymentStatus?: string;
            requestType?: string;
            requestedBy?: string;
            specialUplift?: Array<string>;
        };
        requestUUID?: string;
        status?: string;
        timestamp?: string;
    }) {
        return {
            activity:
                props.activity &&
                Array.isArray(props.activity) &&
                props.activity.length > 0
                    ? props.activity
                    : [],
            flightNumber: props.flightNumber || '',
            flightUUID: props.flightUUID || '',
            localDepartureDate: props.localDepartureDate || '',
            requestContents: {
                item:
                    props.requestContents && props.requestContents.item
                        ? props.requestContents.item
                        : '',
                passengerName:
                    props.requestContents && props.requestContents.passengerName
                        ? props.requestContents.passengerName
                        : '',
                paymentMethod:
                    props.requestContents && props.requestContents.paymentMethod
                        ? props.requestContents.paymentMethod
                        : '',
                paymentStatus:
                    props.requestContents && props.requestContents.paymentStatus
                        ? props.requestContents.paymentStatus
                        : '',
                requestType:
                    props.requestContents && props.requestContents.requestType
                        ? props.requestContents.requestType
                        : '',
                requestedBy:
                    props.requestContents && props.requestContents.requestedBy
                        ? props.requestContents.requestedBy
                        : '',
                specialUplift:
                    props.requestContents &&
                    props.requestContents.specialUplift &&
                    Array.isArray(props.requestContents.specialUplift) &&
                    props.requestContents.specialUplift.length > 0
                        ? props.requestContents.specialUplift
                        : [],
            },
            requestUUID: props.requestUUID || '',
            status: props.status || '',
            timestamp: props.timestamp || '',
        };
    }
}

export class MappedActivity {
    action?: string;
    date?: string;
    icon?: string;
    label?: string;
    who?: string;
    reason?: string;

    constructor(props: {
        action?: string;
        date?: string;
        icon?: string;
        label?: string;
        who?: string;
        reason?: string;
    }) {
        return {
            action: props.action || '',
            date: props.date || '',
            icon: props.icon || '',
            label: props.label || '',
            who: props.who || '',
            reason: props.reason || '',
        };
    }
}

export class AppDataModel {
    content?: DataModel;
    requestForm?: {
        approved?: {}[];
        canceled?: {}[];
        declined?: {}[];
        pending?: {}[];
    };
    selectedRow?: {
        node?: string;
        rowData?: {};
        nodeType?: string;
    };
    filteredValue?: {
        node?: string;
        filterValue?: string;
        rowIndex?: number;
        pageNumber?: number;
        sorting?: any;
    };
    constructor(props: {
        content?: DataModel;
        requestForm?: {
            approved?: {}[];
            canceled?: {}[];
            declined?: {}[];
            pending?: {}[];
        };
        selectedRow?: {
            node?: string;
            rowData?: {};
            nodeType?: string;
        };
        filteredValue?: {
            node?: string;
            filterValue?: string;
            rowIndex?: number;
            pageNumber?: number;
            sorting?: any;
        };
    }) {
        return {
            content: props.content || {},
            requestForm: {
                approved:
                    props.requestForm && props.requestForm.approved
                        ? props.requestForm.approved
                        : [],
                canceled:
                    props.requestForm && props.requestForm.canceled
                        ? props.requestForm.canceled
                        : [],
                declined:
                    props.requestForm && props.requestForm.declined
                        ? props.requestForm.declined
                        : [],
                pending:
                    props.requestForm && props.requestForm.pending
                        ? props.requestForm.pending
                        : [],
            },
            selectedRow: {
                node:
                    props.selectedRow && props.selectedRow.node
                        ? props.selectedRow.node
                        : '',
                rowData:
                    props.selectedRow && props.selectedRow.rowData
                        ? props.selectedRow.rowData
                        : {},
                nodeType:
                    props.selectedRow && props.selectedRow.nodeType
                        ? props.selectedRow.nodeType
                        : '',
            },
            filteredValue: {
                node:
                    props.filteredValue && props.filteredValue.node
                        ? props.filteredValue.node
                        : '',
                filterValue:
                    props.filteredValue && props.filteredValue.filterValue
                        ? props.filteredValue.filterValue
                        : '',
                rowIndex:
                    props.filteredValue && props.filteredValue.rowIndex
                        ? props.filteredValue.rowIndex
                        : 0,
                pageNumber:
                    props.filteredValue && props.filteredValue.pageNumber
                        ? props.filteredValue.pageNumber
                        : 0,
                sorting:
                    props.filteredValue && props.filteredValue.sorting
                        ? props.filteredValue.sorting
                        : null,
            },
        };
    }
}

export class Agents {
    email?: string;
    lang?: any;
    computerName?: string;
    kioskLocation?: string;
    poiID?: string;
    status?: string;
    timestamp?: string;
    kioskInformation?: {
        computerName?: string;
        kioskLocation?: string;
        poiID?: string;
    };

    constructor(props: Agents) {
        let lang = '';
        if (props.lang) {
            if (_.size(props.lang) === 2) {
                lang = 'enes';
            } else if (props.lang[0] === 'en') {
                lang = 'en';
            } else if (props.lang[0] === 'es') {
                lang = 'es';
            }
        }
        return {
            email: props.email ? props.email : '',
            lang: lang ? lang : '',
            computerName:
                props.kioskInformation && props.kioskInformation.computerName
                    ? props.kioskInformation.computerName
                    : '',
            kioskLocation:
                props.kioskInformation && props.kioskInformation.kioskLocation
                    ? props.kioskInformation.kioskLocation
                    : '',
            poiID:
                props.kioskInformation && props.kioskInformation.poiID
                    ? props.kioskInformation.poiID
                    : '',
            status: props.status ? props.status : '',
            timestamp: props.timestamp ? props.timestamp : '',
        };
    }
}

export class Templates {
    templateName?: string;
    equipment?: Array<string>;
    arrivalAirportCode?: Array<string>;
    departureTime?: string;
    containers?: Array<TemplateContainers>;

    constructor(props: Templates) {
        return {
            templateName: props.templateName ? props.templateName : '',
            equipment: props.equipment ? props.equipment : [],
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : [],
            departureTime: props.departureTime ? props.departureTime : '',
            containers: props.containers ? props.containers : [],
        };
    }
}

export class TemplateContainers {
    containerType?: string;
    contentsType?: string;
    containerPosition?: string;
    contents?: Array<TemplateContents>;
    seal?: Array<string>;
    availableForFlight?: string;

    constructor(props: TemplateContainers) {
        return {
            containerType: props.containerType ? props.containerType : '',
            contentsType: props.contentsType ? props.contentsType : '',
            containerPosition: props.containerPosition
                ? props.containerPosition
                : '',
            contents: props.contents ? props.contents : [],
            seal: props.seal ? props.seal : [],
            availableForFlight: props.availableForFlight
                ? props.availableForFlight
                : null,
        };
    }
}

export class TemplateContents {
    quantity?: number;
    item?: string;

    constructor(props: TemplateContents) {
        return {
            quantity: props.quantity ? props.quantity : null,
            item: props.item ? props.item : '',
        };
    }
}

export class TemplatesID {
    templateName?: string;
    equipment?: Array<string>;
    arrivalAirportCode?: Array<string>;
    departureTime?: string;
    containers?: Array<TemplateContainersID>;

    constructor(props: Templates) {
        return {
            templateName: props.templateName ? props.templateName : null,
            equipment: props.equipment ? props.equipment : [],
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : [],
            departureTime: props.departureTime ? props.departureTime : '',
            containers: props.containers ? props.containers : [],
        };
    }
}

export class TemplateContainersID {
    containerType?: string;
    contentsType?: string;
    containerPosition?: string;
    contents?: Array<TemplateContents>;
    id?: number;
    availableForFlight?: string;
    seal?: string[];

    constructor(props: TemplateContainersID) {
        return {
            containerType: props.containerType ? props.containerType : '',
            contentsType: props.contentsType ? props.contentsType : '',
            containerPosition: props.containerPosition
                ? props.containerPosition
                : '',
            contents: props.contents ? props.contents : [],
            id: props.id || props.id === 0 ? props.id : null,
            availableForFlight: props.availableForFlight
                ? props.availableForFlight
                : null,
            seal: props.seal ? props.seal : null,
        };
    }
}

export class FlightInventory {
    flightNumber?: string;
    equipment?: string;
    arrivalAirportCode?: string;
    departureAirportCode?: string;
    flightDate?: string;
    departureTime?: string;
    templateName?: string;
    containers?: Array<TemplateContainers>;
    flightUuid?: string;
    action?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];

    constructor(props: FlightInventory) {
        return {
            flightNumber: props.flightNumber ? props.flightNumber : '',
            equipment: props.equipment ? props.equipment : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            flightDate: props.flightDate ? props.flightDate : '',
            departureTime: props.departureTime ? props.departureTime : '',
            templateName: props.templateName ? props.templateName : '',
            containers:
                props.containers &&
                Array.isArray(props.containers) &&
                props.containers.length > 0
                    ? props.containers
                    : [],
            flightUuid: props.flightUuid ? props.flightUuid : null,
            action: props.action ? props.action : null,
            regNumber: props.regNumber ? props.regNumber : '',
            associatedFlight:
                props.associatedFlight &&
                Array.isArray(props.associatedFlight) &&
                props.associatedFlight.length > 0
                    ? props.associatedFlight
                    : [],
        };
    }
}

export class TemplateContainersQrCode {
    containerType?: string;
    contentsType?: string;
    containerPosition?: string;
    contents?: Array<TemplateContents>;
    availableForFlight?: string;

    ct?: string;
    cct?: string;
    cp?: string;
    cts?: {}[];
    aff?: string;

    constructor(props: TemplateContainersQrCode) {
        return {
            ct: props.containerType ? props.containerType : '',
            cct: props.contentsType ? props.contentsType : '',
            cp: props.containerPosition ? props.containerPosition : '',
            cts: props.contents ? props.contents : [],
            aff: props.availableForFlight ? props.availableForFlight : null,
        };
    }
}

export class FlightInventoryQrCode {
    flightNumber?: string;
    equipment?: string;
    arrivalAirportCode?: string;
    departureAirportCode?: string;
    flightDate?: string;
    departureTime?: string;
    templateName?: string;
    containers?: Array<TemplateContainers>;
    flightUuid?: string;
    action?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];

    fn?: string;
    eq?: string;
    ac?: string;
    dc?: string;
    fd?: string;
    cnts?: Array<TemplateContainers>;
    fid?: string;
    dt?: string;

    constructor(props: FlightInventoryQrCode) {
        return {
            fn: props.flightNumber ? props.flightNumber : '',
            eq: props.equipment ? props.equipment : '',
            ac: props.arrivalAirportCode ? props.arrivalAirportCode : '',
            dc: props.departureAirportCode ? props.departureAirportCode : '',
            fd: props.flightDate ? props.flightDate : '',
            cnts:
                props.containers &&
                Array.isArray(props.containers) &&
                props.containers.length > 0
                    ? props.containers
                    : [],
            fid: props.flightUuid ? props.flightUuid : null,
            dt: props.departureTime ? props.departureTime : '',
        };
    }
}

export class PDFConfig {
    pageSize?: {
        width: number;
        height: number;
    };
    pageOrientation?: string;
    pageMargins?: Array<number>;
    content?: Array<PDFContentModel | string>;
    styles?: {
        headerItem?: {
            fontSize?: number;
            bold?: boolean;
            alignment?: string;
            margin?: number[];
        };
        code?: {
            fontSize?: number;
            bold?: boolean;
            font?: string;
        };
        itemCode?: {
            fontSize?: number;
            font?: string;
        };
        itemList?: {
            margin?: number[];
        };
        qrCode?: {
            margin?: number[];
        };
        tableContainer?: {
            margin?: number[];
        };
    };
    defaultStyle?: {
        columnGap?: number;
    };

    constructor(props?: PDFConfig) {
        return {
            pageSize: props.pageSize
                ? props.pageSize
                : {
                      width: 277,
                      height: 680,
                  },
            pageOrientation: props.pageOrientation
                ? props.pageOrientation
                : 'portrait',
            pageMargins: props.pageMargins
                ? props.pageMargins
                : [30, 16, 30, 0],
            content: props.content ? props.content : [],
            styles: props.styles
                ? props.styles
                : {
                      headerItem: props.styles?.headerItem
                          ? props.styles?.headerItem
                          : {
                                fontSize: props.styles?.headerItem.fontSize
                                    ? props.styles?.headerItem.fontSize
                                    : 18, // 20
                                bold: props.styles?.headerItem.bold
                                    ? props.styles?.headerItem.bold
                                    : true,
                                alignment: 'left',
                                margin: [0, 0, 0, 0],
                            },
                      code: props.styles?.code
                          ? props.styles?.code
                          : {
                                fontSize: props.styles?.code.fontSize
                                    ? props.styles?.code.fontSize
                                    : 9,
                                bold: props.styles?.code.bold
                                    ? props.styles?.code.bold
                                    : true,
                                font: props.styles?.code.font
                                    ? props.styles?.code.font
                                    : 'Roboto',
                            },
                      itemCode: props.styles?.itemCode
                          ? props.styles?.itemCode
                          : {
                                fontSize: props.styles?.itemCode.fontSize
                                    ? props.styles?.itemCode.fontSize
                                    : 8.5,
                                font: props.styles?.itemCode.font
                                    ? props.styles?.itemCode.font
                                    : 'Roboto',
                            },
                      itemList: props.styles?.itemList
                          ? props.styles?.itemList
                          : {
                                margin: [0, 0, 0, 0],
                            },
                      qrCode: props.styles?.qrCode
                          ? props.styles?.qrCode
                          : {
                                margin: [0, 0, 0, 0],
                            },
                      tableContainer: props.styles?.tableContainer
                          ? props.styles?.tableContainer
                          : {
                                margin: [0, 4, 0, 4],
                            },
                  },
            defaultStyle: props.defaultStyle
                ? props.defaultStyle
                : {
                      columnGap: props.defaultStyle?.columnGap
                          ? props.defaultStyle.columnGap
                          : 0,
                  },
        };
    }
}

export class PDFContentModel {
    columns: Array<{ columns: any }>;

    constructor(props?: PDFContentModel) {
        return {
            columns: props.columns ? props.columns : [],
        };
    }
}
export class FlightInventories {
    flightNumber?: string;
    equipment?: string;
    arrivalAirportCode?: string;
    departureAirportCode?: string;
    flightDate?: string;
    departureTime?: string;
    templateName?: string;
    containers?: Array<TemplateContainers>;
    flightUuid?: string;
    activity?: FlightInventoryActivity[];
    lastUpdated?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];
    depScheduled?: string;
    localDepartureDate?: string;

    constructor(props: FlightInventories) {
        return {
            flightNumber: props.flightNumber ? props.flightNumber : '',
            equipment: props.equipment ? props.equipment : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            flightDate: props.flightDate ? props.flightDate : '',
            departureTime: props.departureTime ? props.departureTime : '',
            templateName: props.templateName ? props.templateName : '',
            containers: props.containers ? props.containers : [],
            flightUuid: props.flightUuid ? props.flightUuid : null,
            activity:
                props.activity &&
                Array.isArray(props.activity) &&
                props.activity.length > 0
                    ? props.activity
                    : [],

            lastUpdated: props.lastUpdated ? props.lastUpdated : '',
            regNumber: props.regNumber ? props.regNumber : '',
            associatedFlight: props.associatedFlight
                ? props.associatedFlight
                : [],
            depScheduled: props.depScheduled ? props.depScheduled : '',
            localDepartureDate: props.localDepartureDate
                ? props.localDepartureDate
                : '',
        };
    }
}
export class MappedFlightInventories {
    flightUUID?: string;
    flightDate?: string;
    flightInfo?: string;
    inventoryContainers?: string[];
    containers?: {
        containerPosition?: string;
        containerType?: string;
        contents?: { item?: string; quantity?: number }[];
        contentsType?: string;
        availableForFlight?: string;
        seal?: string[];
    }[];
    templateName?: string;
    departureTime?: string;
    flightNumber?: string;
    departureAirportCode?: string;
    arrivalAirportCode?: string;
    lastUpdated?: string;
    regNumber?: string;
    associatedFlight?: AssociatedFlight[];

    constructor(props: MappedFlightInventories) {
        return {
            flightUUID: props.flightUUID ? props.flightUUID : '',
            flightDate: props.flightDate ? props.flightDate : '',
            flightInfo: props.flightInfo ? props.flightInfo : '',
            inventoryContainers:
                props.inventoryContainers &&
                Array.isArray(props.inventoryContainers) &&
                props.inventoryContainers.length > 0
                    ? props.inventoryContainers
                    : [],
            containers:
                props.containers &&
                Array.isArray(props.containers) &&
                props.containers.length > 0
                    ? props.containers
                    : [],
            templateName: props.templateName ? props.templateName : '',
            departureTime: props.departureTime ? props.departureTime : '',
            flightNumber: props.flightNumber ? props.flightNumber : '',
            departureAirportCode: props.departureAirportCode
                ? props.departureAirportCode
                : '',
            arrivalAirportCode: props.arrivalAirportCode
                ? props.arrivalAirportCode
                : '',
            lastUpdated: props.lastUpdated ? props.lastUpdated : '',
            regNumber: props.regNumber ? props.regNumber : '',
            associatedFlight: props.associatedFlight
                ? props.associatedFlight
                : [],
        };
    }
}

export class FlightInventoryActivity {
    action?: string;
    activityDate?: string;
    who?: string;

    constructor(props: FlightInventoryActivity) {
        return {
            action: props.action ? props.action : '',
            activityDate: props.activityDate ? props.activityDate : '',
            who: props.who ? props.who : '',
        };
    }
}

export class CustomExportTemplate {
    data?: {
        value?: string;
        type?: string;
    };

    constructor(props: CustomExportTemplate) {
        return {
            data: {
                value: props.data && props.data.value ? props.data.value : '',
                type: props.data && props.data.type ? props.data.type : '',
            },
        };
    }
}

export class PDFSecurityReportConfig {
    pageSize?: string;
    header?: any;
    footer?: any;
    pageOrientation?: string;
    pageMargins?: Array<number>;
    content?: { [key: string]: any }[];
    styles?: { [key: string]: any };
    defaultStyle?: {
        columnGap?: number;
    };

    constructor(props?: PDFSecurityReportConfig) {
        return {
            pageSize: props.pageSize ? props.pageSize : 'A4',
            header: props.header ? props.header : {},
            footer: props.footer ? props.footer : {},
            pageOrientation: props.pageOrientation
                ? props.pageOrientation
                : 'portrait',
            pageMargins: props.pageMargins
                ? props.pageMargins
                : [40, 100, 40, 130],
            content: props.content ? props.content : [],
            styles: props.styles
                ? props.styles
                : {
                      header: {
                          margin: [40, 40, 40, 0],
                      },
                      inventoryTitle: {
                          alignment: 'right',
                          fontSize: 18,
                          bold: true,
                      },
                      flightInfo: {
                          fontSize: 10,
                      },
                      contentHeader: {
                          margin: [0, 0, 0, 20],
                      },
                      contentHeaderText: {
                          fontSize: 14,
                      },
                      contentHeaderSubtitle: {
                          fontSize: 10,
                      },

                      contentTableHeader: {
                          fontSize: 11,
                          bold: true,
                      },
                      contentTableData: {
                          fontSize: 11,
                      },

                      footerContainer: {
                          margin: [40, 15, 40, 20],
                      },

                      checkedTitle: {
                          margin: [0, 0, 0, 15],
                          fontSize: 14,
                      },

                      footerInputs: {
                          margin: [0, 0, 0, 8],
                      },
                  },
            defaultStyle: props.defaultStyle
                ? props.defaultStyle
                : {
                      columnGap: props.defaultStyle?.columnGap
                          ? props.defaultStyle.columnGap
                          : 0,
                  },
        };
    }
}

export class MappedMiaAirline {
    code?: string;
    name?: string;
    content?: Array<any>;
    hidden?: boolean;
    image?: string;
    icon?: string;

    constructor(props: MappedMiaAirline) {
        return {
            code: props && props.code ? props.code : '',
            name: props && props.name ? props.name : '',
            content: props && props.content ? props.content : [],
            hidden: props && props.hidden ? props.hidden : false,
            image: props && props.image ? props.image : '',
            icon: props && props.icon ? props.icon : '',
        };
    }
}

export class NormalizeMiaAirline {
    code?: string;
    name?: string;
    content?: Array<any>;
    hidden?: boolean;
    image?: string;
    icon?: string;

    constructor(props: NormalizeMiaAirline) {
        return {
            name: props && props.name ? props.name : '',
            content: props && props.content ? props.content : [],
            hidden: props && props.hidden ? props.hidden : false,
        };
    }
}

export class OperatingHoursRecordsModel {
    date?: string;
    time?: string;
    isEntireDayHours?: boolean;
    selectedDaysArray?: Array<string>;
    openTime?: string;
    closeTime?: string;

    constructor(props: OperatingHoursRecordsModel) {
        return {
            date: props?.date || '',
            time: props?.time || '',
            isEntireDayHours: props?.isEntireDayHours || false,
            selectedDaysArray: props?.selectedDaysArray || [],
            openTime: props?.openTime || '',
            closeTime: props?.closeTime || '',
        };
    }
}

export class OperatingHoursDataModel {
    day: string;
    openingTime: string;
    closingTime: string;

    constructor(props: OperatingHoursDataModel) {
        return {
            openingTime: props?.openingTime || '',
            closingTime: props?.closingTime || '',
            day: props?.day || '',
        };
    }
}

export class MappedMiaPoi {
    poiID?: string;
    key?: string;
    name?: {
        [key: string]: string;
    };
    description?: {
        [key: string]: string;
    };
    phone?: string;
    url?: string;
    landside?: boolean;
    terminal?: string;
    location?: string;
    level?: number;
    tagsDisplayed?: Array<string>;
    tagsHidden?: Array<string>;
    category?: string;
    image?: string;
    icon?: string;
    lat?: number;
    long?: number;
    operatingHours?: Array<OperatingHoursRecordsModel>;
    hidden?: boolean;
    temporaryClosed?: boolean;

    constructor(props: MappedMiaPoi) {
        const nameObj = {};
        const descriptionObj = {};
        copyProps(props.name, nameObj);
        copyProps(props.description, descriptionObj);
        return {
            poiID: props && props.poiID ? props.poiID : '',
            key: props && props.key ? props.key : '',
            name: nameObj,
            description: descriptionObj,
            phone: props && props.phone ? props.phone : '',
            url: props && props.url ? props.url : '',
            landside: props && props.landside ? props.landside : false,
            terminal: props && props.terminal ? props.terminal : '',
            location: props && props.location ? props.location : '',
            level: props && props.level ? props.level : null,
            tagsDisplayed:
                props && props.tagsDisplayed && props.tagsDisplayed.length > 0
                    ? props.tagsDisplayed
                    : [],
            tagsHidden:
                props && props.tagsHidden && props.tagsHidden.length > 0
                    ? props.tagsHidden
                    : [],
            category: props && props.category ? props.category : '',
            image: props && props.image ? props.image : '',
            icon: props && props.icon ? props.icon : '',
            lat: props && props.lat ? props.lat : null,
            long: props && props.long ? props.long : null,
            operatingHours:
                props && props.operatingHours ? props.operatingHours : [],
            hidden: props?.hidden || false,
            temporaryClosed: props?.temporaryClosed || false,
        };
    }
}

export class NormalizeMiaPoi {
    poiID?: string;
    key?: string;
    name?: {
        [key: string]: string;
    };
    description?: {
        [key: string]: string;
    };
    phone?: string;
    url?: string;
    landside?: boolean;
    terminal?: string;
    location?: string;
    level?: number;
    tagsDisplayed?: Array<string>;
    tagsHidden?: Array<string>;
    category?: string;
    image?: string;
    icon?: string;
    lat?: number;
    long?: number;
    operatingHours?: Array<OperatingHoursRecordsModel>;
    hidden?: boolean;
    temporaryClosed?: boolean;

    constructor(props: MappedMiaPoi) {
        const nameObj = {};
        const descriptionObj = {};
        copyProps(props.name, nameObj);
        copyProps(props.description, descriptionObj);
        return {
            poiID: props && props.poiID ? props.poiID : '',
            key: props && props.key ? props.key : '',
            name: nameObj,
            description: descriptionObj,
            phone: props && props.phone ? props.phone : '',
            url: props && props.url ? props.url : '',
            landside: props && props.landside ? props.landside : false,
            terminal: props && props.terminal ? props.terminal : '',
            location: props && props.location ? props.location : '',
            level: props && props.level ? props.level : null,
            tagsDisplayed:
                props && props.tagsDisplayed && props.tagsDisplayed.length > 0
                    ? props.tagsDisplayed
                    : [],
            tagsHidden:
                props && props.tagsHidden && props.tagsHidden.length > 0
                    ? props.tagsHidden
                    : [],
            category: props && props.category ? props.category : '',
            lat: props && props.lat ? props.lat : null,
            long: props && props.long ? props.long : null,
            operatingHours:
                props && props.operatingHours ? props.operatingHours : [],
            hidden: props?.hidden || false,
            temporaryClosed: props?.temporaryClosed || false,
        };
    }
}

export class MappedMiaPoiCategories {
    key?: string;
    name?: {
        en?: string;
        es?: string;
    };
    icon?: string;
    count?: number;

    constructor(props: MappedMiaPoiCategories) {
        return {
            key: props && props.key ? props.key : '',
            name: {
                en: props.name && props.name.en ? props.name.en : '',
                es:
                    props.name && props.name.es
                        ? props.name.es
                        : props.name && props.name.en
                        ? props.name.en
                        : '',
            },
            icon: props && props.icon ? props.icon : '',
            count: props && props.count ? props.count : 0,
        };
    }
}

export class NormalizeMiaPoiCategories {
    key?: string;
    name?: {
        en?: string;
        es?: string;
    };
    icon?: string;
    count?: number;

    constructor(props: MappedMiaPoiCategories) {
        return {
            key: props && props.key ? props.key : '',
            name: {
                en: props.name && props.name.en ? props.name.en : '',
                es:
                    props.name && props.name.es
                        ? props.name.es
                        : props.name && props.name.en
                        ? props.name.en
                        : '',
            },
            count: props && props.count ? props.count : 0,
        };
    }
}

export class AirlinesAPIDataModel {
    [key: string]: {
        hidden: boolean;
        name: string;
        content: Array<{ type: string; en: string; es: string }>;
        icon?: string;
        image?: string;
    };
}

export class ContentModel {
    type?: string;
    value?: string | { en: string; es: string };
    door?: string;
}

export class AirlinesModel {
    [key: string]: {
        name: string;
        hidden: boolean;
        content: Array<ContentModel>;
        icon?: string;
        image?: string;
    };

    constructor(props?: AirlinesModel) {
        return props || new AirlinesModel();
    }
}

export class HomeBannerModel {
    home?: string;
    flights?: string;
    explore?: string;
    defaultPOI?: string;

    constructor(props?: HomeBannerModel) {
        return {
            home: props && props.home ? props.home : '',
            flights: props && props.flights ? props.flights : '',
            explore: props && props.explore ? props.explore : '',
            defaultPOI: props && props.defaultPOI ? props.defaultPOI : '',
        };
    }
}

export class PagesAirlineAppModel {
    pageID?: string;
    title?: {
        [key: string]: string;
    };
    body?: {
        [key: string]: string;
    };
    subtitle?: {
        [key: string]: string;
    };
    hidden?: boolean;
    showOnHome?: boolean;
    icon?: string;
    banner?: string;
    actions?: Array<PageActionsAirlineAppModel>;
    index?: number;

    constructor(props?: PagesAirlineAppModel) {
        const bodyObj = {};
        const titleObj = {};
        const subtitleObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);

        return {
            pageID: props?.pageID || '',
            title: titleObj,
            body: bodyObj,
            subtitle: subtitleObj,
            banner: props?.banner || '',
            icon: props?.icon || '',
            hidden: props?.hidden || false,
            showOnHome: props?.showOnHome || false,
            actions: props?.actions || [],
            index: props?.index || null,
        };
    }
}

export class NormalizePagesAirlineAppModel {
    pageID?: string;
    title?: {
        [key: string]: string;
    };
    body?: {
        [key: string]: string;
    };
    subtitle?: {
        [key: string]: string;
    };
    hidden?: boolean;
    showOnHome?: boolean;
    icon?: string;
    banner?: string;
    actions?: Array<PageActionsAirlineAppModel>;
    index?: number;

    constructor(props?: NormalizePagesAirlineAppModel) {
        const bodyObj = {};
        const titleObj = {};
        const subtitleObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);

        return {
            title: titleObj,
            body: bodyObj,
            subtitle: subtitleObj,
            icon: props?.icon || '',
            hidden: props?.hidden || false,
            showOnHome: props?.showOnHome || false,
            actions: props?.actions || [],
        };
    }
}

export class BannerAirlineAppModel {
    key?: string;
    title?: {
        [key: string]: string;
    };
    body?: {
        [key: string]: string;
    };
    subtitle?: {
        [key: string]: string;
    };
    image?: string;
    hidden?: boolean;

    constructor(props?: BannerAirlineAppModel) {
        const bodyObj = {};
        const titleObj = {};
        const subtitleObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);

        return {
            key: props && props.key ? props.key : '',
            title: titleObj,
            body: bodyObj,
            subtitle: subtitleObj,
            image: props && props.image ? props.image : '',
            hidden: props?.hidden || false,
        };
    }
}

export class NormalizeAirlineAppBannerModel {
    key?: string;
    title?: {
        [key: string]: string;
    };
    body?: {
        [key: string]: string;
    };
    subtitle?: {
        [key: string]: string;
    };

    image?: string;
    hidden?: boolean;

    constructor(props?: NormalizeAirlineAppBannerModel) {
        const bodyObj = {};
        const titleObj = {};
        const subtitleObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);

        return {
            key: props && props.key ? props.key : '',
            title: titleObj,
            body: bodyObj,
            subtitle: subtitleObj,
            hidden: props?.hidden || false,
        };
    }
}

export class OfferAirlineAppModel {
    originAirport?: string;
    destinationAirport?: string;
    currency?: string;
    price?: number;
    featured?: boolean;
    body?: {
        [key: string]: string;
    };
    image?: string;
    ticketConditionLink?: {
        [key: string]: string;
    };
    offerValidity?: {
        from?: string;
        to?: string;
    };
    travelPeriodInbound?: {
        from?: string;
        to?: string;
    };
    travelPeriodOutbound?: {
        from?: string;
        to?: string;
    };
    typeOfTrip?: OfferTripTypeEnum;
    minimumStay?: string;

    constructor(props?: OfferAirlineAppModel) {
        const bodyObj = {};
        const ticketConditionLinkObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.ticketConditionLink, ticketConditionLinkObj);

        return {
            originAirport:
                props && props.originAirport ? props.originAirport : '',
            destinationAirport:
                props && props.destinationAirport
                    ? props.destinationAirport
                    : '',
            currency: props && props.currency ? props.currency : '',
            price: props && props.price ? props.price : 0,
            featured: props && props.featured ? props.featured : false,
            body: bodyObj,
            ticketConditionLink: ticketConditionLinkObj,
            offerValidity: {
                from: props?.offerValidity?.from || '',
                to: props?.offerValidity?.to || '',
            },
            travelPeriodInbound: {
                from: props?.travelPeriodInbound?.from || '',
                to: props?.travelPeriodInbound?.to || '',
            },
            travelPeriodOutbound: {
                from: props?.travelPeriodOutbound?.from || '',
                to: props?.travelPeriodOutbound?.to || '',
            },
            typeOfTrip: props?.typeOfTrip || null,
            minimumStay: props?.minimumStay || '',
            image: props && props.image ? props.image : '',
        };
    }
}

export class NormalizeOfferAirlineAppModel {
    originAirport?: string;
    destinationAirport?: string;
    currency?: string;
    price?: number;
    featured?: boolean;
    body?: {
        [key: string]: string;
    };
    image?: string;
    ticketConditionLink?: {
        [key: string]: string;
    };
    offerValidity?: {
        from?: string;
        to?: string;
    };
    travelPeriodInbound?: {
        from?: string;
        to?: string;
    };
    travelPeriodOutbound?: {
        from?: string;
        to?: string;
    };
    typeOfTrip?: OfferTripTypeEnum;
    minimumStay?: string;

    constructor(props?: NormalizeOfferAirlineAppModel) {
        const bodyObj = {};
        const ticketConditionLinkObj = {};

        copyProps(props.body, bodyObj);
        copyProps(props.ticketConditionLink, ticketConditionLinkObj);

        return {
            originAirport:
                props && props.originAirport ? props.originAirport : '',
            destinationAirport:
                props && props.destinationAirport
                    ? props.destinationAirport
                    : '',
            currency: props && props.currency ? props.currency : '',
            price: props && props.price ? props.price : 0,
            featured: props && props.featured ? props.featured : false,
            body: bodyObj,
            ticketConditionLink: ticketConditionLinkObj,
            offerValidity: {
                from: props?.offerValidity?.from || '',
                to: props?.offerValidity?.to || '',
            },
            travelPeriodInbound: {
                from: props?.travelPeriodInbound?.from || '',
                to: props?.travelPeriodInbound?.to || '',
            },
            travelPeriodOutbound: {
                from: props?.travelPeriodOutbound?.from || '',
                to: props?.travelPeriodOutbound?.to || '',
            },
            typeOfTrip: props?.typeOfTrip || null,
            minimumStay: props?.minimumStay || '',
        };
    }
}

export class AirportAirlineAppModel {
    airportCode?: string;
    airportCity?: string;
    airportCountry?: string;
    lat?: number;
    lon?: number;
    airportName?: string;
    airportTimeZone?: string;
    image?: string;

    constructor(props?: AirportAirlineAppModel) {
        return {
            airportCode: props && props.airportCode ? props.airportCode : '',
            airportCity: props && props.airportCity ? props.airportCity : '',
            airportCountry:
                props && props.airportCountry ? props.airportCountry : '',
            lat: props && props.lat ? props.lat : 0,
            lon: props && props.lon ? props.lon : 0,
            airportName: props && props.airportName ? props.airportName : '',
            airportTimeZone:
                props && props.airportTimeZone ? props.airportTimeZone : null,
            image: props && props.image ? props.image : '',
        };
    }
}

export class NormalizeAirportAirlineAppModel {
    airportCity?: string;
    airportCountry?: string;
    lat?: number;
    lon?: number;
    airportName?: string;
    airportTimeZone?: string;
    image?: string;

    constructor(props?: NormalizeAirportAirlineAppModel) {
        return {
            airportCity: props && props.airportCity ? props.airportCity : '',
            airportCountry:
                props && props.airportCountry ? props.airportCountry : '',
            lat: props && props.lat ? props.lat : 0,
            lon: props && props.lon ? props.lon : 0,
            airportName: props && props.airportName ? props.airportName : '',
            airportTimeZone:
                props && props.airportTimeZone ? props.airportTimeZone : null,
        };
    }
}

export class MappedMiaPoiTags {
    key?: string;
    name?: {
        [key: string]: string;
    };
    icon?: string;
    categories?: Array<string>;
    count?: number;
    isHighlighted?: boolean;
    includesImage?: boolean;

    constructor(props: MappedMiaPoiTags) {
        const nameObj = {};
        copyProps(props.name, nameObj);
        return {
            key: props && props.key ? props.key : '',
            name: nameObj,
            icon: props && props.icon ? props.icon : '',
            categories: props && props.categories ? props.categories : [],
            count: props && props.count ? props.count : 0,
            isHighlighted:
                props && props.isHighlighted ? props.isHighlighted : false,
            includesImage: props && props.icon ? true : false,
        };
    }
}

export class NormalizeMiaPoiTags {
    key?: string;
    name?: {
        [key: string]: string;
    };
    icon?: string;
    categories?: Array<string>;
    count?: number;
    isHighlighted?: boolean;
    includesImage?: boolean;

    constructor(props: NormalizeMiaPoiTags) {
        const nameObj = {};
        copyProps(props.name, nameObj);

        return {
            key: props && props.key ? props.key : '',
            name: nameObj,
            categories: props && props.categories ? props.categories : [],
            count: props && props.count ? props.count : 0,
            isHighlighted:
                props && props.isHighlighted ? props.isHighlighted : false,
        };
    }
}

export class NormalizePagesELP {
    key?: string;
    title?: {
        en?: string;
        es?: string;
    };
    description?: {
        en?: string;
        es?: string;
    };
    image?: string;
    category?: PageCategoryEnum;

    constructor(props: NormalizePagesELP) {
        return {
            key: props?.key || '',
            title: {
                en: props?.title?.en || '',
                es: props?.title?.es
                    ? props.title.es
                    : props?.title?.en
                    ? props.title.en
                    : '',
            },
            description: {
                en: props?.description?.en ? props?.description.en : '',
                es: props?.description?.es
                    ? props.description.es
                    : props?.description?.en
                    ? props.description.en
                    : '',
            },
            category: props?.category || null,
        };
    }
}

export class MappedPagesELP {
    key?: string;
    title?: {
        en?: string;
        es?: string;
    };
    description?: {
        en?: string;
        es?: string;
    };
    image?: string;
    category?: PageCategoryEnum;

    constructor(props: MappedPagesELP) {
        return {
            key: props?.key || '',
            title: {
                en: props?.title?.en || '',
                es: props?.title?.es
                    ? props.title.es
                    : props?.title?.en
                    ? props.title.en
                    : '',
            },
            description: {
                en: props?.description?.en ? props?.description.en : '',
                es: props?.description?.es
                    ? props.description.es
                    : props?.description?.en
                    ? props.description.en
                    : '',
            },
            category: props?.category || null,
            image: props?.image || '',
        };
    }
}

export class EquipmentAirlineAppModel {
    key?: string;
    value?: string;

    constructor(props?: EquipmentAirlineAppModel) {
        return {
            key: props && props.key ? props.key : '',
            value: props && props.value ? props.value : '',
        };
    }
}

export class FaresAirlineAppModel {
    key?: string;
    cabin?: string;
    name?: string;
    rules?: { [key: string]: { value: { [key: string]: string } } };
    fareFieldsItems?: Array<{
        type: string;
        value: { [key: string]: string };
    }>;
    fareFieldsLabels?: Array<string>;

    constructor(props?: FaresAirlineAppModel) {
        return {
            key: props && props.key ? props.key : '',
            cabin: props && props.cabin ? props.cabin : '',
            name: props && props.name ? props.name : '',
            fareFieldsItems: props?.fareFieldsItems || [],
            fareFieldsLabels: props?.fareFieldsLabels || [],
        };
    }
}

export class MappedTopicsModel {
    key: string;
    count: string;
    image?: string;
    name: {
        [key: string]: string;
    };
    description: {
        [key: string]: string;
    };
}

export class NormalizedTopicsModel {
    [key: string]: {
        name: {
            [key: string]: string;
        };
        description: {
            [key: string]: string;
        };
    };
}

export class NormalizedBroadcastsModel {
    id: string;
    title?: {
        [key: string]: string;
    };
    body?: {
        [key: string]: string;
    };
    inAppNotificationDetails?: {
        [key: string]: string;
    };
    image?: string;
    topics?: Array<any>;
    deepLink?: string;
    expiryDate?: string;
    timestamp?: string;
    toggleScheduledTime?: boolean;
    scheduledTime?: string;
    popupInApp?: boolean;
    androidUsers?: boolean;
    appleUsers?: boolean;

    constructor(props: NormalizedBroadcastsModel) {
        const titleObj = {};
        const bodyObj = {};
        const inAppNotificationDetailsObj = {};

        copyProps(props.title, titleObj);
        copyProps(props.body, bodyObj);
        copyProps(props.inAppNotificationDetails, inAppNotificationDetailsObj);

        const broadcast = {
            id: props.id || '',
            title: titleObj,
            body: bodyObj,
            inAppNotificationDetails: inAppNotificationDetailsObj,
            topics: props.topics || [],
            deepLink: props.deepLink || '',
            expiryDate: props.expiryDate || '',
            timestamp: props.timestamp || '',
            popupInApp: props?.popupInApp || false,
            androidUsers: props?.androidUsers || false,
            appleUsers: props?.appleUsers || false,
        };

        if (props?.toggleScheduledTime === true && props?.scheduledTime) {
            broadcast['scheduledTime'] = props?.scheduledTime || '';
        }
        return broadcast;
    }
}

export class ChatbotLogs {
    id: string;
    action?: string;
    targetAction?: string;
    request: string;
    response: string;
    timestamp: string;

    constructor(props: ChatbotLogs) {
        return {
            id: props?.id || '',
            targetAction: props?.action || '',
            request: props?.request || '',
            response: props?.response || '',
            timestamp: props?.timestamp || '',
        };
    }
}

export class NormalizedFareFieldsAirlineAppModel {
    key?: string;
    name: {
        [key: string]: string;
    };
    disabled: boolean;
    icon?: string;

    constructor(props: NormalizedFareFieldsAirlineAppModel) {
        const nameObj = {};

        copyProps(props.name, nameObj);
        return {
            name: nameObj,
            disabled: props?.disabled || false,
            icon: props?.icon || '',
        };
    }
}
export class MappedFareFieldsAirlineAppModel {
    key?: string;
    name: {
        [key: string]: string;
    };
    disabled: boolean;
    icon?: string;

    constructor(props: NormalizedFareFieldsAirlineAppModel) {
        const nameObj = {};

        copyProps(props.name, nameObj);
        return {
            key: props?.key || '',
            name: nameObj,
            disabled: props?.disabled || false,
            icon: props?.icon || '',
        };
    }
}

export class MoreLinksModel {
    id?: string;
    icon?: string; // google icon name
    hidden?: boolean;
    label?: { [key: string]: string };
    actions?: Array<PageActionsAirlineAppModel>;

    constructor(props: MoreLinksModel) {
        const labelObj = {};

        copyProps(props.label, labelObj);

        return {
            id: props?.id || '',
            actions: props?.actions || [],
            icon: props?.icon || '',
            hidden: props?.hidden || false,
            label: labelObj,
        };
    }
}

export class FeaturedCountriesAirlineAppModel {
    id?: string;
    name: {
        [key: string]: string;
    };
    CCA2?: string;
    CCA3?: string;
    dial?: string;

    constructor(props: FeaturedCountriesAirlineAppModel) {
        const nameObj = {};

        copyProps(props.name, nameObj);
        return {
            id: props?.id || '',
            name: nameObj,
            CCA2: props?.CCA2 || '',
            CCA3: props?.CCA3 || '',
            dial: props?.dial || '',
        };
    }
}

export class MappedIntroSlidesAirlineAppModel {
    key?: string;
    color?: string;
    cover?: string;
    backgroundType?: 'cover' | 'color';
    background?: {
        cover?: string;
        color?: string;
    };
    maskType?: MaskTypesEnum;
    imageDisplay?: {
        maskType: MaskTypesEnum;
    };
    image?: string;
    title: {
        [key: string]: string;
    };
    subtitle: {
        [key: string]: string;
    };
    skipButtonText: {
        [key: string]: string;
    };
    actionButtonText: {
        [key: string]: string;
    };
    contentAlignment?: ContentAlignmentEnum;
    hidden?: boolean;

    constructor(props?: MappedIntroSlidesAirlineAppModel) {
        const titleObj = {};
        const subtitleObj = {};
        const skipButtonTextObj = {};
        const actionButtonTextObj = {};
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);
        copyProps(props.skipButtonText, skipButtonTextObj);
        copyProps(props.actionButtonText, actionButtonTextObj);

        return {
            key: props?.key || '',
            contentAlignment: props?.contentAlignment || null,
            hidden: props?.hidden || false,
            maskType: props?.imageDisplay?.maskType || null,
            image: props?.image || '',
            color: props?.background?.color || '',
            cover: props?.cover || '',
            title: titleObj,
            subtitle: subtitleObj,
            skipButtonText: skipButtonTextObj,
            actionButtonText: actionButtonTextObj,
        };
    }
}

export class NormalizedIntroSlidesAirlineAppModel {
    key?: string;
    color?: string;
    cover?: string;
    background?: {
        cover?: string;
        color?: string;
    };
    maskType?: MaskTypesEnum;
    imageDisplay?: {
        maskType: MaskTypesEnum;
    };
    image?: string;
    title: {
        [key: string]: string;
    };
    subtitle: {
        [key: string]: string;
    };
    skipButtonText: {
        [key: string]: string;
    };
    actionButtonText: {
        [key: string]: string;
    };
    contentAlignment?: ContentAlignmentEnum;
    hidden?: boolean;

    constructor(props: NormalizedIntroSlidesAirlineAppModel) {
        const titleObj = {};
        const subtitleObj = {};
        const skipButtonTextObj = {};
        const actionButtonTextObj = {};
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);
        copyProps(props.skipButtonText, skipButtonTextObj);
        copyProps(props.actionButtonText, actionButtonTextObj);

        return {
            key: props?.key || '',
            contentAlignment: props?.contentAlignment || null,
            hidden: props?.hidden || false,
            background: {
                color: props?.color || '',
            },
            imageDisplay: {
                maskType: props?.maskType || null,
            },
            title: titleObj,
            subtitle: subtitleObj,
            skipButtonText: skipButtonTextObj,
            actionButtonText: actionButtonTextObj,
        };
    }
}

export class MappedGeoFencesModel {
    id: string;
    active: boolean;
    lat: number;
    lon: number;
    radius: number;
    title: { [key: string]: string };
    subtitle: { [key: string]: string };
    regionBehavior: string;
    frequency: string;
    startCampaign: string;
    endCampaign: string;
    body: { [key: string]: string };
    popupInApp: boolean;

    constructor(props: MappedGeoFencesModel) {
        const titleObj = {};
        const subtitleObj = {};
        const bodyObj = {};
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);
        copyProps(props.body, bodyObj);

        return {
            id: props?.id || '',
            active: props?.active || false,
            lat: props?.lat || null,
            lon: props?.lon || null,
            radius: props?.radius || null,
            title: titleObj,
            subtitle: subtitleObj,
            frequency: props?.frequency || null,
            regionBehavior: props?.regionBehavior || null,
            startCampaign: props?.startCampaign || '',
            endCampaign: props?.endCampaign || '',
            body: bodyObj,
            popupInApp: props?.popupInApp || false,
        };
    }
}

export class NormalizeGeoFencesModel {
    active: boolean;
    lat: number;
    lon: number;
    radius: number;
    alert: {
        title: { [key: string]: string };
        subtitle: { [key: string]: string };
    };
    regionBehavior: string;
    frequency: string;
    startCampaign: string;
    endCampaign: string;
    body: { [key: string]: string };
    popupInApp: boolean;

    constructor(props: MappedGeoFencesModel) {
        const titleObj = {};
        const subtitleObj = {};
        const bodyObj = {};
        copyProps(props.title, titleObj);
        copyProps(props.subtitle, subtitleObj);
        copyProps(props.body, bodyObj);

        return {
            active: props?.active || false,
            lat: props?.lat || null,
            lon: props?.lon || null,
            radius: +props?.radius || null,
            alert: {
                title: titleObj,
                subtitle: subtitleObj,
            },
            frequency: props?.frequency || null,
            regionBehavior: props?.regionBehavior || null,
            startCampaign: props?.startCampaign || '',
            endCampaign: props?.endCampaign || '',
            body: bodyObj,
            popupInApp: props?.popupInApp || false,
        };
    }
}

export class NormalizedPoisELPModel {
    key: string;
    name: string;
    category: string;
    mapElementID: string;
    icon?: string;
    locationDescription: string;
    description?: string;
    operatingHours?: Array<OperatingHoursRecordsModel>;

    constructor(props?: NormalizedPoisELPModel) {
        return {
            key: props.key || '',
            name: props.name || '',
            category: props.category || '',
            mapElementID: props.mapElementID || '',
            locationDescription: props.locationDescription || '',
            description: props.description || '',
            operatingHours: props?.operatingHours || [],
        };
    }
}
export class MappedPoisELPModel {
    key: string;
    name: string;
    category: string;
    mapElementID: string;
    icon?: string;
    locationDescription: string;
    description?: string;
    operatingHours?: Array<OperatingHoursRecordsModel>;

    constructor(props?: MappedPoisELPModel) {
        return {
            key: props.key || '',
            name: props.name || '',
            category: props.category || '',
            mapElementID: props.mapElementID || '',
            locationDescription: props.locationDescription || '',
            description: props.description || '',
            icon: props.icon || '',
            operatingHours: props?.operatingHours || [],
        };
    }
}

export class NormalizedHotelsAttractionsELPModel {
    id?: string;
    name?: string;
    description?: {
        en?: string;
        es?: string;
    };
    address?: string;
    location?: string;
    phoneNumber?: string;
    websiteURL?: string;
    rating?: string;
    placeID?: string;
    imageURL?: string;

    constructor(props?: NormalizedHotelsAttractionsELPModel) {
        return {
            id: props.id || '',
            name: props.name || '',
            description: {
                en: props.description?.en || '',
                es: props.description?.es || '',
            },
            address: props.address || '',
            location: props.location || '',
            phoneNumber: props.phoneNumber || '',
            websiteURL: props.websiteURL || '',
            rating: props.rating || '',
            placeID: props.placeID || '',
        };
    }
}

export class MappedHotelsAttractionsELPModel {
    id?: string;
    name?: string;
    description?: {
        en?: string;
        es?: string;
    };
    address?: string;
    location?: string;
    phoneNumber?: string;
    websiteURL?: string;
    rating?: string;
    placeID?: string;
    imageURL?: string;

    constructor(props?: MappedHotelsAttractionsELPModel) {
        return {
            id: props.id || '',
            name: props.name || '',
            description: {
                en: props.description?.en || '',
                es: props.description?.es || '',
            },
            address: props.address || '',
            location: props.location || '',
            phoneNumber: props.phoneNumber || '',
            websiteURL: props.websiteURL || '',
            rating: props.rating || '',
            placeID: props.placeID || '',
            imageURL: props.imageURL || '',
        };
    }
}

const copyProps = (obj, targetObj) => {
    if (obj) {
        Object.keys(obj).forEach((key) => {
            targetObj[key] = obj[key] ? obj[key] : obj.en ? obj.en : '';
        });
    }
};
