import { Menu } from '@app/models/menu.model';

export const menuConfig: Menu[] = [
    {
        headerTitle: 'MENU.CONTENT',
        roles: [],
    },
    {
        title: 'MENU.AIRLINES',
        path: '/dashboard/content/airlines',
        icon: 'icofont icofont-airplane',
        routeName: 'airlines',
        roles: [],
        name: 'airlines',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.POIS',
        path: '/dashboard/content/pois',
        icon: 'icofont icofont-bullseye',
        routeName: 'pois',
        name: 'pois',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.TAGS',
        path: '/dashboard/content/tags',
        icon: 'icofont icofont-ui-tag',
        routeName: 'tags',
        name: 'tags',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.PAGES',
        path: '/dashboard/content/pages',
        icon: 'icofont icofont-file-document',
        routeName: 'pages',
        roles: [],
        name: 'pages',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.CITIES',
        path: '/dashboard/content/cities',
        icon: 'icofont icofont-building-alt',
        routeName: 'cities',
        roles: [],
        name: 'cities',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.CONFIG',
        path: '/dashboard/content/config',
        icon: 'icofont icofont-settings-alt',
        routeName: 'config',
        roles: [],
        name: 'config',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.HOME_BANNERS',
        path: '/dashboard/content/home_banners',
        icon: 'icofont icofont-book-mark',
        routeName: 'home_banners',
        roles: [],
        name: 'home_banners',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.NOTIFICATIONS',
        roles: ['admin'],
    },
    {
        title: 'MENU.TOPICS',
        path: '/dashboard/content/topics',
        icon: 'icofont icofont-dashboard-web',
        routeName: 'topics',
        roles: ['admin'],
        name: 'topics',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.FLIGHTS',
        path: '/dashboard/content/flightNotifications',
        icon: 'icofont icofont-ui-flight',
        routeName: 'flightNotifications',
        roles: ['admin'],
        name: 'flightNotifications',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.BROADCASTS',
        path: '/dashboard/content/broadcasts',
        icon: 'icofont icofont-ui-messaging',
        routeName: 'broadcasts',
        roles: ['admin'],
        name: 'broadcasts',
        type: 'link',
        active: false,
    },
    {
        subSeparator: true,
        roles: ['admin'],
    },
    {
        title: 'MENU.GEO_LOCATION',
        path: '/dashboard/content/geofences',
        icon: 'icofont icofont-map-pins',
        routeName: 'geofences',
        roles: ['admin'],
        name: 'geofences',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.CONFIG',
        roles: ['admin'],
    },
    {
        title: 'MENU.USERS',
        path: '/dashboard/content/users',
        icon: 'icofont icofont-people',
        routeName: 'users',
        roles: ['admin'],
        name: 'users',
        type: 'link',
        active: false,
    },
];
